let commonConfig = {
  TOKEN_ENDPOINT: "https://apicallhttps.iiithcanvas.com/token",
  AUTHORIZE_ENDPOINT: "https://apim.iiithcanvas.com/oauth2/authorize",
  RESPONSE_TYPE: "code",
  SCOPE: "openid",
  GRANT_TYPE: "authorization_code",
  LOGOUT_URL: "https://apim.iiithcanvas.com/oidc/logout",
  COOKIE_PATH: "/"
};

export const getConfig = () => {
  let environmentName = process.env.REACT_APP_ENV;
  switch (environmentName.toLowerCase()) {
    // Production Config
    case "prod":
      let prodConfig = {
        REDIRECT_URI: "http://canvas.iiithcanvas.com/login",
        CLIENT_ID: "YsKEGp6KUnpGTJuC8Li_JMx51t0a",
        CLIENT_SECRET: "oGOi6O9FPTxltIxtwQQSRL8LoBga",
        CLIENT_URL: "http://canvas.iiithcanvas.com/"
      };
      return { ...commonConfig, ...prodConfig };

    // Dev Config
    case "dev":
      let devConfig = {
        REDIRECT_URI: "http://dev-canvas.iiithcanvas.com/login",
        CLIENT_ID: "NKfR1i3ors6J0MGBsL9wi0MPtE4a",
        CLIENT_SECRET: "n7TfbSSUQi0_o2XxCXWfr4AgAQoa",
        CLIENT_URL: "http://dev-canvas.iiithcanvas.com/"
      };
      return { ...commonConfig, ...devConfig };

    // Local Config
    case "local":
      let localConfig = {
        REDIRECT_URI: "http://localhost:3000/login",
        CLIENT_ID: "1EzyOCsH7tNZa1ytszaSo9zkHBwa",
        CLIENT_SECRET: "W8gWa8zZuVcVXHOO43t7WB0STfUa",
        CLIENT_URL: "http://localhost:3000/"
      };
      return { ...commonConfig, ...localConfig };
  }
};

export const getTokenString = () => {
  let config = getConfig();
  let stringToConvert = config.CLIENT_ID + ":" + config.CLIENT_SECRET;
  let tokenString = btoa(stringToConvert);
  return tokenString;
};

export const getBackendLink = () => {
  let environmentName = process.env.REACT_APP_ENV;

  switch (environmentName) {
    case "local":
      return "http://localhost:4000/graphql";
    case "dev":
      return "https://dev-backend.iiithcanvas.com/graphql";
    case "prod":
    default:
      return "https://backend.iiithcanvas.com/graphql";
  }
};
