import React, { useState, useEffect } from "react";
import { Card, Grid, Paper, Typography, CardMedia, CardContent, CardActionArea, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grow from "@material-ui/core/Grow";
import "../../styles/DomainList.css";
import { useWindowDimensions } from "./WindowDimensions";
import { CardHeader } from "react-md";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    transition: "all 400ms ease-in-out",
    root: {
      maxWidth: 345
    },

    "& .card-icon": {
      fontSize: 80
      // transition: "all 400ms ease-in-out",
    },
    "&:hover": {
      // transform: "translateY(-4px)",
      color: palette.secondary.main,

      "& .card-icon": {
        // transform: "translateY(-8px)",
        color: palette.secondary.main,
        opacity: 0.6
      }
    }
  },

  media: {
    height: 250,
    width: 500,
  }
}));

const ListOfDomains = (props) => {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only("xs"));

  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(undefined);
  const [showPopup, setShowPopup] = useState({
    Applications: [false, false],
    Workbenches: [false],
    "APIs and Utilities": [false]
  });
  const [enteredPopup, setEnteredPopup] = useState({
    Applications: [false, false],
    Workbenches: [false],
    "APIs and Utilities": [false]
  });

  const storeLists = [
    {
      listType: "Applications",
      listItems: [
        {
          name: "Post Edit",
          link: "https://posteditme.in/",
          image: "TaskManagerIcon.png",
          description: "Process and Manage post edit jobs",
          category: "Applications",
          screenshots: [
            "TaskManagerScreenshot1.jpg",
            "TaskManagerScreenshot2.jpg",
            "TaskManagerScreenshot3.jpg",
            "TaskManagerScreenshot4.jpg"
          ]
        },
        {
          name: "Usertube",
          link: "http://usertube.iiithcanvas.com/swayamhome",
          image: "UsertubeIcon.png",
          description: "Discover all courses",
          category: "Applications",
          screenshots: [
            "UsertubeScreenshot1.jpg",
            "UsertubeScreenshot2.jpg",
            "UsertubeScreenshot3.jpg",
            "UsertubeScreenshot4.jpg"
          ]
        },
      ]
    },
    {
      listType: "Workbenches",
      listItems: [
        {
          name: "Transzaar",
          link: "https://transzaar.com/",
          image: "TranszaarIcon.jpg",
          description: "Translation tool and management platform",
          category: "Workbench",
          screenshots: [
            "TranszaarScreenshot1.jpg",
            "TranszaarScreenshot2.jpg",
            "TranszaarScreenshot3.jpg",
            "TranszaarScreenshot4.jpg"
          ]
        },
        {
          name: "Task Manager",
          link: "http://taskmanager.iiithcanvas.com/",
          image: "TaskManagerIcon.png",
          description: "Process and Manage translation jobs",
          category: "Applications",
          screenshots: [
            "TaskManagerScreenshot1.jpg",
            "TaskManagerScreenshot2.jpg",
            "TaskManagerScreenshot3.jpg",
            "TaskManagerScreenshot4.jpg"
          ]
        },
        {
          name: "Video Editor",
          link: "https://usertube.iiithcanvas.com/videoeditor",
          image: "UsertubeIcon.png",
          description: "Process and Manage Speech to Speech translation jobs",
          category: "Applications",
          screenshots: [
            "TaskManagerScreenshot1.jpg",
            "TaskManagerScreenshot2.jpg",
            "TaskManagerScreenshot3.jpg",
            "TaskManagerScreenshot4.jpg"
          ]
        }
      ]
    },

    {
      listType: "APIs and Utilities",
      listItems: [
        {
          name: "API Manager",
          link: "https://devportal.iiithcanvas.com/login",
          image: "APIManagerIcon.png",
          description:
            "Interface and management tool for core translation APIS",
          category: "Utilities",
          screenshots: []
        }
      ]
    }
  ];

  const classes = useStyles();

  const storeTitle = (
    <Grid
      item
      container
      style={{ height: isXS ? "initial" : "80px" }}
      justify="center"
      alignItems="flex-start"
    >
      <Typography
        variant="h4"
        style={{ fontWeight: "bold", color: "rgba(11, 94, 151, 1)" }}
      >
        What's in store?
      </Typography>
    </Grid>
  );

  return (
    <>
      <Grid
        container
        xs={12}
        direction="column"
        alignItems="center"
        style={{ marginBottom: isXS ? "10vh" : 0 }}
      >
        {isXS ? storeTitle : null}
      </Grid>
      <Grid
        container
        xs={12}
        direction="column"
        alignItems="center"
        style={{
          backgroundColor: isXS ? "#eaf2f9" : "white",
          padding: isXS ? "20px 20px 0px" : "initial",
          marginBottom: "15vh"
        }}
      >
        {isXS ? null : storeTitle}

        {storeLists.map((storeList, listIndex) => (
          <>
            <Grid
              container
              style={{ height: isXS ? "initial" : "60px" }}
              justify={isXS ? "flex-start" : "center"}
              alignItems={"center"}
            >
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", color: "rgba(11, 94, 151, 1)" }}
              >
                {storeList["listType"]}
              </Typography>
            </Grid>
            <Grid
              container
              justify="center"
              style={{ marginBottom: isXS ? "30px" : "initial" }}
            >
              <Grid
                item
                container
                xs={isXS ? 12 : 11}
                spacing={2}
                style={{ paddingTop: isXS ? "20px" : "initial" }}
              >
                {storeList.listItems.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={4}
                    direction="column"
                    alignItems="center"
                    style={{ position: "relative" }}
                  >

                    {isXS ? (
                      <Grid
                        item
                        container
                        xs={12}
                        alignItems="flex-start"
                        style={{ height: isXS ? "100%" : "initial" }}
                      >
                        <Grid item container xs={4} alignItems="center">
                          <img
                            src={`images/${item.image}`}
                            width="80px"
                            height="80px"
                            style={{ borderRadius: "5px" }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant="body1"
                            style={{ fontWeight: "bold", color: "#005893" }}
                          >
                            {item.name}
                          </Typography>
                        </Grid>
                        <Grid item container xs={1} />
                        <Grid item container xs={3}>
                          <Grid
                            item
                            container
                            justify="center"
                            alignItems="center"
                            style={{
                              marginTop: "10px",
                              borderRadius: "30px",
                              height: "30px",
                              width: "75px",
                              border: "1px solid #005893"
                            }}
                          >
                            <a
                              href={item.link}
                              target="_blank"
                              style={{
                                color: "#005893",
                                fontWeight: "bold",
                                fontSize: "15px"
                              }}
                            >
                              OPEN
                            </a>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <a
                        href={item.link}
                        target="_blank"
                        style={{ height: "145px" }}
                      >
                        <Grid item container alignItems="center">

                          <Card elevation={0} style={{ borderRadius: "0px" }}>
                            <CardActionArea>
                              <CardMedia

                                className={classes.media}
                                image={`images/${item.image}`}
                                title="Paella dish"
                              />
                              <div className={classes.details}>

                              </div>
                              <CardContent>
                                <Grid container direction="row" spacing={1}>
                                  <Grid item sm={2}>
                                    <Avatar variant="square" className={classes.square} style={{ height: "50px", width: "100%", backgroundColor: "rgba(11, 94, 151, 1)" }}>
                                      {item.name.charAt(0).toUpperCase()}
                                    </Avatar>
                                  </Grid>
                                  <Grid item sm={8}>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{ text: "bold" }}>
                                      {item.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                      {item.description}
                                    </Typography>
                                  </Grid>
                                </Grid>

                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      </a>

                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        ))}
      </Grid>
    </>
  );
};

export default ListOfDomains;
