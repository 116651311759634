import React, { useRef } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
// Redirect
import "react-perfect-scrollbar/dist/css/styles.css";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Scrollbar from "react-perfect-scrollbar";
import { Theme } from "./theme";
import GlobalCss from "./styles/jss/GlobalCss";
import DiscoverPage from "./home/DiscoverPage";
import AboutPage from "./home/AboutPage";
import LoginPage from "./home/LoginPage";
import HomePage from "./home/Homepage";
import LandingPage from "./home/LandingPage";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { isMobile } from "react-device-detect";
import { getBackendLink } from "./config";

const apolloCache = new InMemoryCache();

const client = new ApolloClient({
  cache: apolloCache,
  link: createUploadLink({
    uri: getBackendLink(),
    // uri: "https://backend.iiithcanvas.com/graphql",
    // "https://backend.iiithcanvas.com/graphql",
    // "https://dev-backend.iiithcanvas.com/graphql",
    // "http://localhost:4000/graphql",
  }),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    mutate: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

function App(props) {
  const divRef = useRef(null);
  const parent1 = document.querySelector("#parent1");
  if (isMobile) {
    setTimeout(() => {
      // if(divRef.current && divRef.current.children[0]) {
      divRef.current.children[0].children[2].style.width = "0%";
      divRef.current.children[0].children[2].children[0].style.width = "0%";
      // } else {
      //   setTimeout(())
      // }
    }, 5000);
  }

  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <MuiThemeProvider theme={Theme}>
          <GlobalCss>
            {isMobile ? (
              <div id="parent1" ref={divRef}>
                <Scrollbar
                  className="h-full-screen scrollable-content"
                  option={{ suppressScrollX: true }}
                >
                  <Router basename="/">
                    <Switch>
                      <Route path="/login" component={LoginPage} />
                      {/* <Route path="/landing" component={LandingPage} /> */}
                      <Route path="/" component={LandingPage} />
                      {/* <Redirect path="/" exact to="landing" /> */}
                      {/* <Route component={Error} /> */}
                    </Switch>
                  </Router>
                </Scrollbar>
              </div>
            ) : (
              <Scrollbar
                className="h-full-screen scrollable-content"
                option={{ suppressScrollX: true }}
              >
                <Router basename="/">
                  <Switch>
                    <Route path="/login" component={LoginPage} />
                    {/* <Route path="/landing" component={LandingPage} /> */}
                    <Route path="/" component={LandingPage} />
                    {/* <Redirect path="/" exact to="landing" /> */}
                    {/* <Route component={Error} /> */}
                  </Switch>
                </Router>
              </Scrollbar>
            )}
          </GlobalCss>
        </MuiThemeProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  );
}

export default App;
