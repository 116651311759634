import React, { useEffect, useState } from "react";
import Aside from "../Components/Main/SideBar";
import NavBar from "../Components/Main/Navbar";
import { Grid, List, ListSubheader, IconButton, Drawer, Divider, ListItem, ListItemText, Typography } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import '../index.css';
import TopBar1 from "../Components/Main/TopBar";
import { scrollTo } from "../utils";
import About from "../Components/Main/About";
import Banner from "../Components/Main/Banner";
import FinancialCredits from '../Components/Main/FinancialCredits';
import Credits from '../Components/Main/Credits';
import ListOfDomains from "../Components/Main/DomainList";
import Footer from '../Components/Main/Footer';
import Card1 from "../Components/Main/test";
import { getConfig, getTokenString } from "../config";
import {
  getSessionParameter,
  getAllSessionParameters,
  decodeIdToken,
  initAuthenticatedSession,
} from "../actions/session";
import AppModal from "../Components/Main/DomainListComponents/AppModal";

//modules for drawer code
import MenuIcon from '@material-ui/icons/Menu'; 
import clsx from 'clsx';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useWindowDimensions } from "../Components/Main/WindowDimensions";

const CONFIG = getConfig();

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  category: {
    width: "100%",
    backgroundColor: "#fff"
  },
  hero: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://images.unsplash.com/photo-1558981852-426c6c22a060?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80')`,
    height: "500px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "4rem",
    [theme.breakpoints.down("sm")]: {
      height: 300,
      fontSize: "3em"
    }
  },
  categoryShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth - 40,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  noCategoryShift: {

  },
  menuButton: {
    position: "absolute",
    bottom: "1px",
    marginRight: theme.spacing(0),
    marginLeft: "1%",
    zIndex: 1
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
//   drawerPaper: {
//     width: drawerWidth,
//   },
  drawerHeader: {
    borderTop: "1px solid #e0e0e0",
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }
}));

const LandingPage = (props) => {
  
  //code for drawer
  const theme = useTheme();
  
  const classes = useStyles();
  
  const [open, setOpen] = React.useState(false);
  const [appModalData, setAppModalData] = React.useState(undefined);

  const {width, height} = useWindowDimensions();
  const [mobileDisplay, setMobileDisplay] = useState(false);

  if(width < 960) {
    if(mobileDisplay == false) {
      setMobileDisplay(true);
    }
  } else {
    if(mobileDisplay ==true) {
      setMobileDisplay(false);
    }
  }

  useEffect(() => {

  }, [appModalData]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //drawer end
  
  useEffect(() => {
    scrollTo("root");
  });

  const [menuItem, setMenuItem] = useState("Applications");

  useEffect(() => {
    if(menuItem == 'API Manager')
      setMenuItem("Applications");
  }, [menuItem]);

  const token = getSessionParameter("ACCESS_TOKEN");

  if (!token) {
    const code = new URL(window.location.href).searchParams.get("code");

    if (code) {
      fetch("https://apicallhttps.iiithcanvas.com/token", {
        method: "POST",
        headers: {
          //prod instance
          // Authorization:
          //   "Basic aVRxcUtBeGFOMWZEbE9yeW5FOExIZmNkTkVZYTpSTW5PVmVNZ0JUWjZ5WVZhSnZDNVlWYVVTNFVh",
          //dev instance
          // Authorization:
          //   "Basic M05rRWpTaFpUdGt1WlRZYVJnOW14aFJJZlp3YTpXUm1GMTY5VW4zZnpIVDBYUWQ5dkdScXY2RTBh",
          //localhost instance
          Authorization:
            "Basic " + getTokenString(),
          "Content-Type": "application/x-www-form-urlencoded",
        },
        // body: 'grant_type=password&username=admin&password=admin&scope=openid'
        body: `grant_type=authorization_code&code=${code}&redirect_uri=${CONFIG.REDIRECT_URI}&scope=openid&client_id=${CONFIG.CLIENT_ID}`,
      })
        .then((response) => {
          if (response.status !== 200) {
            return Promise.reject(
              new Error(
                "Invalid status code received in the token response: " +
                  response.status
              )
            );
          }
          return response.json();
        })
        .then((responseData) => {
          initAuthenticatedSession(responseData);
          return [responseData, decodeIdToken(responseData.id_token)];
        })
        .then((responseData) => {
          const token = getSessionParameter("ACCESS_TOKEN");
          return responseData;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
    // }
  }

  return (
    <div
      className="landing"
      style={{ width: "100%", height: "flex"}}
    >
      <Grid container>
        <TopBar1 />

        <Banner />

        {/* <Credits /> */}

        {/* <FinancialCredits /> */}
        
        <ListOfDomains />

        <Footer />
      </Grid>
    </div>
  );
};

export default LandingPage;
