import React from 'react'
import { Link } from 'react-router-dom';
import {AppBar, Toolbar } from '@material-ui/core'
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from 'clsx';
import { Tabs, Tab, IconButton, Drawer, Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';


const drawerWidth = 130;

const useStyles = makeStyles((theme) => ({
        appBar: {
          backgroundColor: "#fff"
        },
        hero: {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://images.unsplash.com/photo-1558981852-426c6c22a060?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80')`,
          height: "500px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          fontSize: "4rem",
          [theme.breakpoints.down("sm")]: {
            height: 300,
            fontSize: "3em"
          }
        },
        appBarShift: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
        menuButton: {
          marginRight: theme.spacing(10),
        },
        hide: {
          display: 'none',
        },
        drawer: {
          width: drawerWidth,
          flexShrink: 0,
        },
        drawerPaper: {
          width: drawerWidth,
        },
        drawerHeader: {
          display: 'flex',
          alignItems: 'center',
          padding: theme.spacing(0, 1),
          // necessary for content to be below app bar
          ...theme.mixins.toolbar,
          justifyContent: 'flex-end',
        },
        content: {
          flexGrow: 1,
          padding: theme.spacing(3),
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          marginLeft: -drawerWidth,
        },
        contentShift: {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
        }
 }));

const NavBar = (props) => {
    const [open, setOpen] = React.useState(false);
    
    const theme = useTheme();

    const classes = useStyles();

    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };
//   render() {
    return (
      <>
        <AppBar className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })} title="My App" style={{background: 'linear-gradient(to right, #36D1DC, #5B86E5)'}}>
          <Toolbar>  
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
            <Link to={{ pathname: "/" }} style={{color: "#000000"}}>
                <span style={{fontWeight: "bolder"}}>SWAYAM&nbsp;</span> IIIT
            </Link>
            <Tabs className="m-auto">
            <a href="/home"> <Tab label="&nbsp;Home&nbsp;"  style={{color: "#000000"}}/></a>
            <a href="/about"><Tab label="&nbsp;About Us&nbsp;"  style={{color: "#000000"}}/></a>
                <a href="/discover"><Tab label="&nbsp;Discover&nbsp;"  style={{color: "#000000"}}/></a>
            </Tabs>
            </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem button key={"API Manager"} onClick={e=>{props.menuItemFunc("API Manager")}}>
                <a href="https://apim.iiithcanvas.com" target="_blank"><ListItemText primary="API Manager" /></a>
            </ListItem>
            {['Applications', 'Workbench', 'Utilities'].map((text, index) => (
              <ListItem button key={text} selected={text === props.menuItem} onClick={e=>{props.menuItemFunc(text)}}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {['Categories', 'Translation'].map((text, index) => (
              <ListItem button key={text}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
    </>
    )
  }
// }

export default NavBar;

