import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Grid, IconButton, Fade } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import image1 from "../../../Static/UsertubeScreenshot1.jpg";
import { red } from "@material-ui/core/colors";
function getModalStyle() {
  const top = 50;
  const left = 40;

  return {
    top: `48.5%`,
    left: `50%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    background: red,
    width: 900,
    height: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 3, 3)
  }
}));

const ScreenshotModal = (props) => {
  const classes = useStyles();
  
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.setScreenshotImage(undefined);
  };

  if(props.image == null) {
    return (<></>)
  }
  
  else if(open == false)
    setOpen(true);
  
  else { 
    return (
      <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
              <img src={props.image} height="500px" width="900px" />
          </div>
      </Modal>
    </div>
    )
  }
}

export default ScreenshotModal