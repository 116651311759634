import { makeStyles } from "@material-ui/core/styles";

export const landingStyles = makeStyles(({ palette, ...theme }) => ({
  "@global": {
    ".intro": { width: "100%", height: "569px", backgroundColor: "#f9f9f9" },
    ".landing": { color: "rgba(0, 0, 0, 0.87)", overflow: "hidden" },
    ".landing p": { color: "rgba(var(--body), 0.74)" },
    ".landing a": { textDecoration: "none" },
    ".section-intro": { paddingTop: "7.5rem !important" },
    ".container": {
      padding: "0px",
      maxWidth: "100%",
      margin: "0 auto"
    },
    ".header": {
      position: "fixed",
      width: "100%",
      top: "0",
      background: "var(--bg-paper)",
      left: "0",
      right: "0",
      padding: "20px 0",
      transition: "padding 0.3s linear",
      zIndex: "999999"
    },
    ".header.header-fixed": {
      padding: "10px 0",
      background: "#ffffff",
      boxShadow: "0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28)",
      zIndex: "999"
    },
    ".header .header-container": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    ".header .header-container ul.navigation": {
      listStyle: "none",
      margin: "0 0 0 30px",
      padding: "0"
    },
    ".header .header-container ul.navigation li": { display: "inline-block" },
    ".header .header-container ul.navigation li a": {
      display: "flex",
      alignItems: "center",
      padding: "8px 15px",
      borderRadius: "4px",
      transition: "all 0.3s ease-in",
      height: "21px",
      fontFamily: "Helvetica",
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      color: "#3757a1"
    },
    ".header .header-container ul.navigation li a:hover": {
      background: "#f0f0f0"
    },
    ".MuiInputLabel-root": {
      border: "1px solid #005893",
      borderRadius: "12px",
      height: "30px",
      width: "150px",
      backgroundColor: "#eaf2f9"
    },
    ".MuiNativeSelect-outlined": {
      padding: 0
    }
    // '.MuiButton-root': {
    //   border: '1px solid #005893',
    //   borderRadius: '12px',
    //   backgroundColor: '#eaf2f9'
    // },
    // '.MuiButton-outlined': {
    //   border: '1px solid #005893',
    //   borderRadius: '12px',

    // },
    // '.MuiButton-outlinedSecondary': {
    //   color: '#005893',
    //   border: '1px solid #005893',
    //   borderRadius: '12px',
    // }
  }
}));
