import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import NativeSelect from "@material-ui/core/NativeSelect";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { useQuery, useLazyQuery, useMutation } from "react-apollo";
import { Tooltip, Button } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { useMemo } from "react";

import {
  GET_SOURCE_LANGUAGES,
  GET_TARGET_LANGUAGES,
  GET_API_LIST,
  UPLOAD_FILE,
  RUN_MT_FOR_TEXT,
  RUN_MT_INDEPENDENT,
} from "../../../queries/TaskQueries";
import {
  GET_TEMP_TASK_BY_ID,
  NEW_TEMP_TASK,
} from "../../../queries/TempTaskQueires";

// import apis from "../../../jsons/apis.json";
// import { SchemaMetaFieldDef } from "graphql";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      fontSize: "clamp(8px, 1.5vh, 1.5vh)", //"calc(12px + 1.5vmin)",
      height: "9.26vh",
    },
  },

  textField: {
    padding: 5,
  },
  nativeInput: {
    padding: "5px 10px",
  },
  outlined: {
    paddingRight: 0,
  },

  button1: {
    "&:hover": {
      background: "none",
    },
  },

  icon: {
    fill: "brown",
  },
  noBorder: {
    border: "none",
  },
});

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-evenly",
  padding: "20px",
  borderWidth: 1,
  width: "50px",
  height: "46.51%",
  borderColor: "#005893",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#005893",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function MTCard(props) {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only("xs"));
  const isSM = useMediaQuery(theme.breakpoints.only("sm"));

  const classes = useStyles();
  const setMTValue = {};

  //States
  const [inputFileType, setInputFileType] = useState(null);

  const [MTapi, setMTapi] = useState(null);
  setMTValue["api"] = setMTapi;
  const [MTsource, setMTsource] = useState("English"); //"eng");
  setMTValue["source"] = setMTsource;
  const [MTtarget, setMTtarget] = useState("Hindi");
  setMTValue["target"] = setMTtarget;
  const [MTInputText, setMTInputText] = useState("");
  setMTValue["inputText"] = setMTInputText;

  const [targetLanguageList, setTargetLanguageList] = useState(null);

  const [errorStatement, setErrorStatement] = useState(null);

  const [fileURL, setFileURL] = useState(null);
  const [filename, setFileName] = useState(null);

  const [MTOutputText, setMTOutputText] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  // const [endpoint, setEndpoint] = useState(
  //   "https://apicallhttps.iiithcanvas.com/iiitilmt/v.1.0.0/mt_linker"
  // );

  const [isDocumentMode, setIsDocumentMode] = useState(false);
  const [documentModeState, setDocumentModeState] = useState("selectFile");
  const [file, setFile] = useState(null);

  const [tempTaskCompleted, toggleTempTaskCompleted] = useState(false);
  const [tempTaskID, setTempTaskID] = useState();
  const [outputFileURL, setOutputFileURL] = useState();
  const [pollID, setPollID] = useState();

  let errorSection = (
    <Grid
      item
      container
      justify="center"
      alignItems="center"
      xs={12}
      style={{ height: "30px" }}
    >
      {errorStatement ? (
        <>
          <Grid item style={{ marginRight: 10 }}>
            <img src="images/Error.png" alt="err" />
          </Grid>
          <span
            style={{
              color: "#d01010",
              fontSize: "clamp(12px, 1.43vh, 1.43vh)",
            }}
          >
            {errorStatement}
          </span>
        </>
      ) : null}
    </Grid>
  );

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ noClick: true, noKeyboard: true });

  if (
    !errorStatement &&
    acceptedFiles.length &&
    documentModeState == "selectFile"
  ) {
    setFile(acceptedFiles[0]);
    setDocumentModeState("fileSelected");
  }

  useEffect(() => {
    if (file) {
      let outputArray = file.name.split(".");
      setInputFileType(outputArray[outputArray.length - 1]);
    }
  }, [file]);

  useEffect(() => {
    console.log("setting error statement", errorStatement);
  }, [errorStatement]);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  const {
    called: calledSourceLanguages,
    loading: loadingSourceLanguages,
    error: errorloadingSource,
    data: sourceLanguages,
  } = useQuery(GET_SOURCE_LANGUAGES, {
    variables: {
      taskType: "MT",
    },
  });

  useEffect(() => {
    setErrorStatement(null);
  }, [isDocumentMode]);

  const [
    uploadFile,
    { loading: uploading, error: uploadError, data: fileDetails },
  ] = useMutation(UPLOAD_FILE, {
    onCompleted: (data) => {
      // let outputArray = data.uploadFile.uri.split('.');
      // setInputFileType(outputArray.length-1);
      // runMTIndependent({
      //   variables: {
      //     apiName: MTapi,
      //     scriptURL: data.uploadFile.uri,
      //     sourceLanguage: MTsource,
      //     targetLanguage: MTtarget,
      //     outputType: inputFileType
      //     //outputArray[outputArray.length-1]
      //   }
      // });

      let parsedURL = data.uploadFile.uri.split("/");
      let extractedFilename = parsedURL[parsedURL.length - 1].split(".");
      let fileExtension = extractedFilename[extractedFilename.length - 1];
      newTempTask({
        variables: {
          Type: "MT",
          APIName: MTapi,
          InputFileURL: data.uploadFile.uri,
          SourceLanguage: MTsource,
          TargetLanguage: MTtarget,
          OutputType: fileExtension,
        },
      });
    },
    onError: (err) => {
      errorMT(err);
      if (acceptedFiles.length) acceptedFiles.pop();
      setDocumentModeState("selectFile");
    },
  });

  const [
    getTargetLanguages,
    { called: is_called, loading: loading, error: error, data: data },
  ] = useLazyQuery(GET_TARGET_LANGUAGES, {
    onCompleted: (data) => {},
    onError: (err) => errorMT(err),
  });

  const [
    runMTForText,
    {
      called: called_runmt,
      loading: loading_runmt,
      error: error_runmt,
      data: data_runmt,
    },
  ] = useLazyQuery(RUN_MT_FOR_TEXT, {
    onCompleted: (data) => {},
    onError: (err) => errorMT(err),
  });

  const [
    runMTIndependent,
    {
      called: called_runmt_file,
      loading: loading_runmt_file,
      error: error_runmt_file,
      data: data_runmt_file,
    },
  ] = useLazyQuery(RUN_MT_INDEPENDENT, {
    onCompleted: (data) => {
      var xhr = new XMLHttpRequest(),
        a = document.createElement("a"),
        file;

      xhr.open("GET", data.runMTIndependent, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        file = new Blob([xhr.response], {
          type:
            inputFileType == "pdf"
              ? "application/pdf"
              : inputFileType == "vtt" || inputFileType == "txt"
              ? "text/json"
              : "application/octet-stream",
        });

        let temp = URL.createObjectURL(file);
        setFileURL(temp);

        a.click();
      };
      xhr.send();
    },
    onError: (err) => {
      errorMT(err);
      if (acceptedFiles.length) acceptedFiles.pop();
      setDocumentModeState("selectFile");
    },
  });

  const [
    getTempTask,
    { loading: fetchingTempTask, error: errorInTempTask, data: tempTask },
  ] = useLazyQuery(GET_TEMP_TASK_BY_ID, {
    fetchPolicy: "network-only",
    skip: !tempTaskID || (tempTaskID && tempTaskCompleted),
    variables: { tempTaskID: tempTaskID },
  });

  const startPollingTempTask = () => {
    const pollTempTask = setInterval(() => {
      getTempTask({ tempTaskID: tempTaskID });
    }, 5000);
    setPollID(pollTempTask);
  };

  const stopPollingTempTask = () => {
    clearInterval(pollID);
  };

  const [
    newTempTask,
    {
      loading: creatingNewTempTask,
      error: errorCreatingNewTempTask,
      data: newTempTaskData,
    },
  ] = useMutation(NEW_TEMP_TASK, {
    onCompleted: (data) => {
      let id = data.newTempTask._id;
      setTempTaskID(id);
      startPollingTempTask();
      setOutputFileURL(null);
    },
  });

  useEffect(() => {
    if (tempTask && tempTask.getTempTaskByID) {
      let status = tempTask.getTempTaskByID.Status;
      if (tempTask.getTempTaskByID._id == tempTaskID) {
        if (status === "Completed") {
          toggleTempTaskCompleted(true);
          stopPollingTempTask();
          let audioURL = tempTask.getTempTaskByID.OutputFileURL;

          var xhr = new XMLHttpRequest(),
            a = document.createElement("a"),
            file;

          xhr.open("GET", tempTask.getTempTaskByID.OutputFileURL, true);
          xhr.responseType = "blob";
          xhr.onload = function () {
            file = new Blob([xhr.response], {
              type:
                inputFileType == "pdf"
                  ? "application/pdf"
                  : inputFileType == "vtt" || inputFileType == "txt"
                  ? "text/json"
                  : "application/octet-stream",
            });

            let temp = URL.createObjectURL(file);
            setFileURL(temp);

            a.click();
          };
          xhr.send();
        } else if (status === "Error") {
          console.log("MT Failed!");
          stopPollingTempTask();
          setShowLoader(false);
          errorMT("Error in MT!");
          if (acceptedFiles.length) acceptedFiles.pop();
          setDocumentModeState("selectFile");
        }
      }
    }
  }, [tempTask]);

  useEffect(() => {
    if (fileURL) {
      let filename = file.name.split(".");

      let filename1 = filename.reduce((result, currentValue, index, array1) => {
        if (index == array1.length - 1)
          return result + "mtscript." + currentValue;
        else if (index == 0) return result + currentValue;
        else return result + "." + currentValue;
      }, "");

      setFileName(filename1);
      setDocumentModeState("translated");
    }
  }, [fileURL]);

  useEffect(() => {
    if (loading_runmt && called_runmt) {
      setMTOutputText("");
      setShowLoader(true);
    } else setShowLoader(false);
  }, [loading_runmt]);

  const [
    getAPIList,
    {
      called: is_called_api,
      loading: loading_api,
      error: error_api,
      data: data_api,
    },
  ] = useLazyQuery(GET_API_LIST, {
    onCompleted: (data) => {
      if (data.findAPIs) setMTapi(data.findAPIs[0].APIName);
    },
    onError: (err) => errorMT(err),
  });

  useEffect(() => {
    if (data) {
      setTargetLanguageList(data.availableTargets);
    }
  }, [data]);

  useEffect(() => {
    if (targetLanguageList) {
      if (MTtarget == targetLanguageList[0]) {
        getAPIList({
          variables: {
            tasktype: "MT",
            sourcelang: MTsource,
            targetlang: MTtarget,
          },
        });
      } else setMTtarget(targetLanguageList[0]);
    }
  }, [targetLanguageList]);

  useEffect(() => {
    getTargetLanguages({
      variables: {
        sourceLanguage: MTsource,
      },
    });
  }, [MTsource]);

  useEffect(() => {
    getAPIList({
      variables: {
        tasktype: "MT",
        sourcelang: MTsource,
        targetlang: MTtarget,
      },
    });

    if (MTInputText) {
      setMTOutputText("");
      runMTForText({
        variables: {
          apiName: MTapi,
          text: MTInputText,
          sourceLanguage: MTsource,
          targetLanguage: MTtarget,
        },
      });
    }
  }, [MTtarget]);

  // useEffect(() => {
  //   if(sourceLanguages && sourceLanguages.getSourceLanguages)
  //   setSourceLanguageList(sourceLanguages.getSourceLanguages);
  // }, [sourceLanguages])

  useEffect(() => {
    if (data_runmt != undefined) setMTOutputText(data_runmt.runMTForText);
  }, [data_runmt]);

  // const [sourceLanguageList, setSourceLanguageList] = useState([
  //   {
  //     sourceLanguageName: "English",
  //     sourceLanguageValue: "eng",
  //     targetLanguages: [
  //       {
  //         targetLanguageName: "Hindi",
  //         targetLanguageValue: "hin"
  //       },
  //       {
  //         targetLanguageName: "Telugu",
  //         targetLanguageValue: "tel"
  //       },
  //       {
  //         targetLanguageName: "Marathi",
  //         targetLanguageValue: "mar"
  //       },
  //     ]
  //   },
  //   {
  //     sourceLanguageName: "Hindi",
  //     sourceLanguageValue: "hin",
  //     targetLanguages: [
  //       {
  //         targetLanguageName: "Telugu",
  //         targetLanguageValue: "tel"
  //       },
  //       {
  //         targetLanguageName: "Gujarati",
  //         targetLanguageValue: "guj"
  //       },
  //       {
  //         targetLanguageName: "Marathi",
  //         targetLanguageValue: "mar"
  //       },
  //       {
  //         targetLanguageName: "Punjabi",
  //         targetLanguageValue: "pun"
  //       },
  //       {
  //         targetLanguageName: "English",
  //         targetLanguageValue: "eng"
  //       }
  //     ]
  //   },
  //   {
  //     sourceLanguageName: "Marathi",
  //     sourceLanguageValue: "mar",
  //     targetLanguages: [
  //       {
  //         targetLanguageName: "Telugu",
  //         targetLanguageValue: "tel"
  //       },
  //       {
  //         targetLanguageName: "Gujarati",
  //         targetLanguageValue: "guj"
  //       },
  //       {
  //         targetLanguageName: "Punjabi",
  //         targetLanguageValue: "pun"
  //       },
  //       {
  //         targetLanguageName: "English",
  //         targetLanguageValue: "eng"
  //       }
  //     ]
  //   }
  // ]);

  const copyTextToClipboard = (event) => {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = MTOutputText;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  const handleChangeMT = (inputFrom, e) => {
    e.preventDefault();
    setErrorStatement(null);

    // if (inputFrom == "api") {
    //   let sourceLanguages, endpoint, targetLanguages;
    //   for (let i = 0; i < apis.length; i++) {
    //     if (apis[i].apiName == e.target.value) {
    //       sourceLanguages = apis[i].sourceLanguages;
    //       endpoint = apis[i].endpoint;
    //       targetLanguages = apis[i].sourceLanguages[0].targetLanguages;
    //       break;
    //     }
    //   }
    //   setEndpoint(endpoint);
    //   setSourceLanguageList(sourceLanguages);

    //   setMTOutputText("");

    // }

    setMTValue[inputFrom](e.target.value);
  };

  useEffect(() => {
    if (MTInputText) {
      runMTForText({
        variables: {
          apiName: MTapi,
          text: MTInputText,
          sourceLanguage: MTsource,
          targetLanguage: MTtarget,
        },
      });
    } else setMTOutputText("");
  }, [MTInputText]);

  useEffect(() => {
    setMTInputText("");
    setMTOutputText("");
    setErrorStatement(null);
  }, [MTsource]);

  useEffect(() => {
    if (MTInputText) {
      setErrorStatement(null);
      setMTOutputText("");
      runMTForText({
        variables: {
          apiName: MTapi,
          text: MTInputText,
          sourceLanguage: MTsource,
          targetLanguage: MTtarget,
        },
      });
    }
  }, [MTapi]);

  function errorMT(err) {
    console.log("Error in mt is", err);
    if (err) {
      setErrorStatement("Technical problem. Try again!");
    }
  }

  function toggleCardMode(mode) {
    const blueBackgroundMT = document.getElementById("blueBackgroundMT");
    const diff = blueBackgroundMT.offsetHeight;

    document.getElementById(mode + "MT").style.opacity = 0;
    document.getElementById(mode + "SelectedMT").style.opacity = 1;
    if (mode == "Document") {
      document.getElementById("TextSelectedMT").style.opacity = 0;
      document.getElementById("TextMT").style.opacity = 1;

      document.getElementById("TextSelectedMT").zIndex = -1;
      document.getElementById("TextMT").style.zIndex = 1;
      document.getElementById("DocumentSelectedMT").zIndex = 1;
      document.getElementById("DocumentMT").style.zIndex = -1;

      blueBackgroundMT.style.transform = "translate(" + -diff + "px, 0px)";
      setIsDocumentMode(true);
    } else if (mode == "Text") {
      document.getElementById("DocumentSelectedMT").style.opacity = 0;
      document.getElementById("DocumentMT").style.opacity = 1;

      document.getElementById("TextSelectedMT").zIndex = 1;
      document.getElementById("TextMT").style.zIndex = -1;
      document.getElementById("DocumentSelectedMT").zIndex = -1;
      document.getElementById("DocumentMT").style.zIndex = 1;

      blueBackgroundMT.style.transform = "translate(" + 0 + "px, 0px)";
      setIsDocumentMode(false);
    }
  }

  function translateFile() {
    uploadFile({
      variables: { file: file },
    });
    setDocumentModeState("translating");
  }

  function closeFile() {
    acceptedFiles.pop();
    setDocumentModeState("selectFile");
    setFileURL(null);
  }

  function getFileSize(fileSize) {
    return fileSize < 1024
      ? fileSize + " bytes"
      : fileSize < 1024 * 1024
      ? (fileSize / 1024).toFixed(2) + "kb"
      : fileSize < 1024 * 1024 * 1024
      ? (fileSize / (1024 * 1024)).toFixed(2) + "mb"
      : (fileSize / (1024 * 1024 * 1024)).toFixed(2) + "gb";
  }

  let fileSize;
  if (acceptedFiles.length) {
    fileSize = getFileSize(acceptedFiles[0].size);
  }

  const documentCode =
    documentModeState == "selectFile" ? (
      <>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          Drag 'n' drop file(txt, pdf or vtt)
          <div>or</div>
          <button
            type="button"
            onClick={() => {
              open();
              setErrorStatement(null);
            }}
            style={{
              border: "none",
              backgroundColor: "#005893",
              color: "white",
              height: "2.6vh",
              minHeight: "20px",
            }}
          >
            Browse
          </button>
        </div>
        {errorSection ? errorSection : null}
      </>
    ) : documentModeState == "fileSelected" ||
      documentModeState == "translated" ? (
      <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          container
          justify="center"
          style={{ height: 35, color: "#7b7b7b" }}
        >
          <Grid item container justify="space-between" xs={8}>
            <Grid item>
              {documentModeState == "translated" ? (
                <span>File is ready</span>
              ) : null}
            </Grid>
            <Grid item container xs={1} justify="center" alignItems="center">
              <input
                type="image"
                src="images/Close.png"
                height="10"
                onClick={closeFile}
                style={{ zIndex: 10 }}
              />
            </Grid>
          </Grid>
          {documentModeState == "translated" ? <Grid item xs={1} /> : null}
        </Grid>
        <Grid item container justify="center">
          <Grid
            item
            container
            xs={8}
            style={{
              backgroundColor: "#e2f0fc",
              height: "52px",
              borderRadius: "0.6vh",
            }}
          >
            <a
              href={documentModeState == "fileSelected" ? "#" : fileURL}
              target="_blank"
              style={{ display: "flex", height: "100%", width: "inherit" }}
            >
              <Grid item container justify="center" alignItems="center" xs={2}>
                <img src="images/File.png" height="25" />
              </Grid>
              <Grid
                item
                container
                xs={10}
                style={{ height: isXS ? "100%" : "auto" }}
              >
                <Grid
                  item
                  container
                  direction={"column"}
                  justify="center"
                  style={{
                    height: isXS ? "100%" : "auto",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Grid
                    item
                    style={{
                      color: "#005893",
                      wordBreak: "break-all",
                      height: "auto",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        display: "inline-block",
                        height: 22,
                        width: 145,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      title={
                        documentModeState == "fileSelected"
                          ? file.name
                          : filename
                      }
                    >
                      {documentModeState == "fileSelected"
                        ? file.name
                        : filename}
                    </p>
                  </Grid>
                  {
                    <Grid item style={{ color: "#909090" }}>
                      {fileSize}
                    </Grid>
                  }
                </Grid>
              </Grid>
            </a>
          </Grid>
          {documentModeState == "translated" ? (
            <Grid item container xs={1} justify="center" alignItems="center">
              <a href={fileURL} download={filename}>
                <img src="images/Download.png" height="16" />
              </a>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={8}
          style={{ height: "52px", minHeight: "40px" }}
          justify="flex-end"
          alignItems="center"
        >
          {documentModeState == "translated" ? null : (
            <button
              style={{
                backgroundColor: "#005893",
                color: "white",
                height: "60%",
                border: "none",
                borderRadius: "4px",
              }}
              onClick={translateFile}
            >
              Convert
            </button>
          )}
        </Grid>
      </Grid>
    ) : documentModeState == "translating" ? (
      <Grid item container direction="column" alignItems="center">
        <Grid>
          <CircularProgress size={50} style={{ color: "#005893" }} />
        </Grid>
        <Grid style={{ color: "#005893" }}>
          &nbsp;&nbsp;&nbsp;Converting....
        </Grid>
      </Grid>
    ) : null;

  return (
    <Grid item container>
      <Grid
        id="2"
        item
        container
        style={{
          minWidth: "267px",
          minHeight: "270px",
          width: isXS ? "80vw" : isSM ? "50vw" : "28vw",
          height: "38.33vh",
          animation: "compress-card .6s ease-out forwards",
        }}
        onClick={(e) => {
          props.gridClick(e);
        }}
      >
        <Grid item container xs={12} style={{ width: "100%", height: "10.3%" }}>
          <Grid item xs={6} style={{ height: "100%" }}>
            <Tooltip
              title="MT related details are here.MT related details are here.MT related details are here."
              placement="top-start"
            >
              <Button disableRipple={true} className={classes.button1}>
                Translate
              </Button>
            </Tooltip>
          </Grid>
          <Grid
            item
            container
            xs={6}
            justify="flex-end"
            style={{ height: "100%" }}
          >
            <Grid
              item
              container
              justify="flex-end"
              style={{ height: "inherit", position: "relative" }}
            >
              <div
                id="blueBackgroundMT"
                style={{
                  height: "inherit",
                  width: document.getElementById("blueBackgroundMT")
                    ? document.getElementById("blueBackgroundMT").offsetHeight
                    : 0,
                  backgroundColor: "#005893",
                  position: "absolute",
                  right: 0,
                  zIndex: -2,
                }}
              ></div>
              <span
                style={{
                  display: "flex",
                  position: "relative",
                  width: document.getElementById("blueBackgroundMT")
                    ? document.getElementById("blueBackgroundMT").offsetHeight
                    : 0,
                  height: document.getElementById("blueBackgroundMT")
                    ? document.getElementById("blueBackgroundMT").offsetHeight
                    : 0,
                }}
              >
                <img
                  id="DocumentMT"
                  src="images/Document.png"
                  alt="Doc"
                  style={{ position: "relative", bottom: 2, height: "inherit" }}
                  onClick={() => toggleCardMode("Document")}
                />
                <img
                  id="DocumentSelectedMT"
                  src="images/DocumentSelected.png"
                  alt="Doc"
                  style={{
                    position: "absolute",
                    height: "inherit",
                    opacity: 0,
                    zIndex: -1,
                  }}
                />
              </span>
              <span
                style={{
                  display: "flex",
                  position: "relative",
                  width: document.getElementById("blueBackgroundMT")
                    ? document.getElementById("blueBackgroundMT").offsetHeight
                    : 0,
                  height: document.getElementById("blueBackgroundMT")
                    ? document.getElementById("blueBackgroundMT").offsetHeight
                    : 0,
                }}
              >
                <img
                  id="TextMT"
                  src="images/Text.png"
                  alt="Txt"
                  style={{
                    position: "relative",
                    bottom: 2,
                    height: "inherit",
                    opacity: 0,
                  }}
                  onClick={() => toggleCardMode("Text")}
                />
                <img
                  id="TextSelectedMT"
                  src="images/TextSelected.png"
                  alt="Txt"
                  style={{ height: "inherit", position: "absolute" }}
                />
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Paper
          elevation={0}
          style={{
            width: "100%",
            height: "89.7%",
            border: "2px solid rgb(11, 94, 151)",
            borderRadius: "0px",
            marginBottom: "5%",
            pointerEvents: "none",
            opacity: "0.92",
          }}
        >
          <Grid
            container
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{ paddingBottom: "0px", height: "100%" }}
          >
            <Grid item container>
              <Grid
                item
                container
                xs={3}
                md={3}
                lg={3}
                xl={2}
                justify="flex-start"
              >
                <NativeSelect
                  value={MTsource}
                  className={`${classes.outlined} ${classes.nativeInput}`}
                  size="small"
                  style={{ color: "rgb(11, 94, 151)" }}
                  onChange={(e) => handleChangeMT("source", e)}
                  disableUnderline={true}
                  variant="outlined"
                >
                  {sourceLanguages &&
                    sourceLanguages.getSourceLanguages.map((language) => (
                      <option value={language}>{language}</option>
                    ))}
                </NativeSelect>
              </Grid>
              <Grid
                item
                container
                justify="center"
                alignItems="center"
                xs={1}
                md={1}
                lg={1}
                xl={1}
              >
                <img
                  src="/images/TranslateTo.png"
                  width="13px"
                  height="12px"
                ></img>
              </Grid>
              <Grid item container xs={3} md={3} lg={3} xl={3}>
                <NativeSelect
                  className={`${classes.outlined} ${classes.nativeInput}`}
                  value={MTtarget}
                  size="small"
                  onChange={(e) => handleChangeMT("target", e)}
                  disableUnderline={true}
                  style={{ color: "rgb(11, 94, 151)" }}
                >
                  {targetLanguageList
                    ? targetLanguageList.map((targetLanguage) => (
                        <option value={targetLanguage}>{targetLanguage}</option>
                      ))
                    : null}
                </NativeSelect>
              </Grid>

              <Grid
                item
                container
                xs={5}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                justify="flex-end"
                style={{ paddingRight: "10px" }}
              >
                <NativeSelect
                  className={`${classes.outlined} ${classes.nativeInput}`}
                  value={MTapi}
                  size="small"
                  onChange={(e) => handleChangeMT("api", e)}
                  disableUnderline={true}
                  style={{ color: "rgb(11, 94, 151)", maxWidth: 100 }} //, minWidth: 140 }}
                >
                  {data_api &&
                    data_api.findAPIs.map((api) => (
                      <option value={api.APIName}>{api.APIName}</option>
                    ))}
                </NativeSelect>
              </Grid>
            </Grid>

            <Grid
              item
              container
              justify={
                documentModeState == "fileSelected" ? "center" : "flex-start"
              }
              alignItems={
                isDocumentMode && documentModeState == "selectFile"
                  ? "flex-end"
                  : isDocumentMode
                  ? "center"
                  : "flex-start"
              }
              style={{
                width: "100%",
                height: "67.9%",
              }}
            >
              {isDocumentMode ? (
                documentCode
              ) : (
                <>
                  <Grid item container xs={12} md={12} lg={12} xl={12}>
                    <TextField
                      multiline
                      fullWidth
                      placeholder="Please Enter Some Text"
                      value={MTInputText}
                      variant="outlined"
                      rows={2}
                      onChange={(e) => handleChangeMT("inputText", e)}
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                      style={{
                        width: "100%",
                        backgroundColor: "rgba(240, 240, 240, 1)",
                        borderRadius: "0px",
                      }}
                      className={classes.root}
                    />
                  </Grid>

                  {errorSection ? errorSection : null}

                  <Grid item container xs={12} md={12} lg={12} xl={12}>
                    <TextField
                      multiline
                      fullWidth
                      value={MTOutputText}
                      variant="outlined"
                      rows={2}
                      placeholder={showLoader ? "Loading..." : ""}
                      style={{
                        width: "100%",
                        backgroundColor: "rgba(240, 240, 240, 1)",
                        border: "0px",
                      }}
                      className={classes.root}
                      InputProps={{
                        readOnly: true,
                        classes: { notchedOutline: classes.noBorder },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={copyTextToClipboard}>
                              <FileCopyOutlinedIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              justify="flex-end"
              alignItems="flex-end"
              style={{
                width: "100%",
                height: "12.4%",
                color: "#005893",
                paddingRight: "10px",
              }}
            >
              <Grid item>{/* powered by IIIT */}</Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default MTCard;
