import React, { useEffect, useState } from "react";
import { scrollTo } from "../utils";
import Aside from "../Components/Main/SideBar";
import NavBar from "../Components/Main/Navbar";
import { Grid, List, ListSubheader, IconButton, Drawer, Divider, ListItem, ListItemText, Typography } from "@material-ui/core";
import TopBar1 from "../Components/Main/TopBar";
import About from "../Components/Main/About";
import Banner from "../Components/Main/Banner";
import ListOfDomains from "../Components/Main/DomainList";
import Card1 from "../Components/Main/test";
import { Redirect } from "react-router-dom";
import { getConfig, getTokenString } from "../config";
import {
  getSessionParameter,
  getAllSessionParameters,
  decodeIdToken,
  initAuthenticatedSession,
} from "../actions/session";
import AppModal from "../Components/Main/DomainListComponents/AppModal";

//modules for drawer code
import MenuIcon from '@material-ui/icons/Menu'; 
import clsx from 'clsx';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const CONFIG = getConfig();

const drawerWidth = 150;

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff"
  },
  hero: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://images.unsplash.com/photo-1558981852-426c6c22a060?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80')`,
    height: "500px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "4rem",
    [theme.breakpoints.down("sm")]: {
      height: 300,
      fontSize: "3em"
    }
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    borderTop: "1px solid #e0e0e0",
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }
}));

const DiscoverPage = () => {
  
  //code for drawer
  const theme = useTheme();
  
  const classes = useStyles();
  
  const [open, setOpen] = React.useState(false);
  const [appModalData, setAppModalData] = React.useState(undefined)

  useEffect(() => {

  }, [appModalData]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //drawer end
  
  useEffect(() => {
    scrollTo("root");
  });

  const [menuItem, setMenuItem] = useState("Applications");

  useEffect(() => {
    console.log("menu item is", menuItem);
    if(menuItem == 'API Manager')
      setMenuItem("Applications");
  }, [menuItem]);

  const token = getSessionParameter("ACCESS_TOKEN");
  console.log("session ", token);
  if (!token) {
    const code = new URL(window.location.href).searchParams.get("code");
    // if (!code){
    //   alert("You must log in first");
    //   return <Redirect to="/login" />;
    // }
    // else{
    console.log("code is here again ", code);
    if (code) {
      console.log("got code here ", code);
      fetch("https://apicallhttps.iiithcanvas.com/token", {
        method: "POST",
        headers: {
          //prod instance
          // Authorization:
          //   "Basic aVRxcUtBeGFOMWZEbE9yeW5FOExIZmNkTkVZYTpSTW5PVmVNZ0JUWjZ5WVZhSnZDNVlWYVVTNFVh",
          //dev instance
          // Authorization:
          //   "Basic M05rRWpTaFpUdGt1WlRZYVJnOW14aFJJZlp3YTpXUm1GMTY5VW4zZnpIVDBYUWQ5dkdScXY2RTBh",
          //localhost instance
          Authorization:
            "Basic " + getTokenString(),
            // anNSd3hzeXd6dE5jRDJiRTFzYTNYazV3TEJZYTplYWRZZ01NYmlDWm9KUVVPc2JLTWFFQ1JqR2th",
            "Content-Type": "application/x-www-form-urlencoded",
        },
        // body: 'grant_type=password&username=admin&password=admin&scope=openid'
        body: `grant_type=authorization_code&code=${code}&redirect_uri=${CONFIG.REDIRECT_URI}&scope=openid&client_id=${CONFIG.CLIENT_ID}`,
      })
        .then((response) => {
          if (response.status !== 200) {
            return Promise.reject(
              new Error(
                "Invalid status code received in the token response: " +
                  response.status
              )
            );
          }
          return response.json();
        })
        .then((responseData) => {
          console.log(responseData);
          initAuthenticatedSession(responseData);

          return [responseData, decodeIdToken(responseData.id_token)];
        })
        .then((responseData) => {
          const token = getSessionParameter("ACCESS_TOKEN");
          console.log("inside session ", token);
          alert("Welcome, " + responseData[1].sub);
          return responseData;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
    // }
  }

  return (
    <div
      className="landing"
      style={{ width: "100%", height: "flex"}}
    >
      
      {/* <NavBar menuItemFunc={setMenuItem} menuItem={menuItem}/> */}
      <TopBar1 />
      <Banner />

      
      
      <Grid container spacing={3} justify="center" style={{marginTop: "20px", padding: "0px"}}>
        {/* <Grid item xs={12} sm={2}>
          <Aside />
        </Grid> */}
        
        {/* <Grid
          item
          container
          justify="center"
          alignItems="center"
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          
          style={{background: "white", padding: 15, fontSize: 22}}
          >
            {menuItem}
          </Grid> */}

        <Grid
          id="g1"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            //#e4e6eb
            background:"white",
            paddingLeft: "0px",
            paddingRight: "0px",
            paddingTop: "0px",
            height: "flex",
            // overflowY: "scroll",
            paddingBottom: "100px",
            marginBottom: "100px"
          }}
          container
          flexDirection="column"
          justify="center"
        >
          {/* <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            >
          </Grid> */}

          {/* <NavBar menuItemFunc={setMenuItem} menuItem={menuItem}/> */}
      
            {/* <AppModal app={appModalData ? appModalData : null} setAppModalData={setAppModalData}/> */}
          <ListSubheader style={{width: "1330px"}}>
            
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open)}
              // style={{background: "#e4e6eb"}}
            >
              <MenuIcon />
            </IconButton>
            
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={open}
              PaperProps={{ style: { position: "absolute", left: "-20px", width: "150px", height: "810px"} }}
              BackdropProps={{ style: { position: "absolute", left: "-20px" } }}
              ModalProps={{
                container: document.getElementById("g1"),
                style: { position: "absolute", left: "-20px" },
              }}
              // SlideProps={{
              //   onExiting: (node) => {
              //     // node.style.webkitTransform = "scaleX(0)";
              //     // node.style.transform = "scaleX(0)";
              //     // node.style.transformOrigin = "top left ";
              //   },
              // }}
              // classes={{
                //   paper: classes.drawerPaper,
              // }}
            >
              <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </div>
              <Divider />
              <List>
                <ListItem button key={"API Manager"} onClick={() => setMenuItem("API Manager")}>
                    <a href="https://apim.iiithcanvas.com" target="_blank" style={{color: "black"}}><ListItemText primary="API Manager" /></a>
                </ListItem>
                {['Applications', 'Workbench', 'Utilities'].map((text, index) => (
                  <ListItem button key={text} selected={text === menuItem} onClick={e=>{setMenuItem(text)}}>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
              <Divider />
              <List>
                {['Categories', 'Translation'].map((text, index) => (
                  <ListItem button key={text}>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
            </Drawer>
              <Typography gutterBottom variant="h5" component="h1" style={{position: "absolute", bottom: "1px", left: "45%"}}>
                {menuItem}
              </Typography>
          </ListSubheader> 
        
        <Grid 
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open
          })}>
          <ListOfDomains category={ menuItem } setAppModalData={ setAppModalData }/>
        </Grid>

        </Grid>
      </Grid>
      
      <Divider />
      {/* <About /> */}
    </div>
  );
};

export default DiscoverPage;
