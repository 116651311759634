import gql from "graphql-tag";

const GET_SOURCE_LANGUAGES = gql`
  query($taskType: String!) {
    getSourceLanguages(taskType: $taskType)
  }
`;
export { GET_SOURCE_LANGUAGES };

const GET_TARGET_LANGUAGES = gql`
  query($sourceLanguage: String) {
    availableTargets(sourceLang: $sourceLanguage)
  }
`;
export { GET_TARGET_LANGUAGES };

const RUN_MT_FOR_TEXT = gql`
  query($apiName: String, $text: String, $sourceLanguage: String, $targetLanguage: String) {
    runMTForText(apiName: $apiName, text: $text, sourceLanguage: $sourceLanguage, targetLanguage: $targetLanguage)
  }
`;
export { RUN_MT_FOR_TEXT };

const RUN_MT_INDEPENDENT = gql`
  query($apiName: String, $scriptURL: String, $sourceLanguage: String, $targetLanguage: String, $outputType: String) {
    runMTIndependent(apiName: $apiName, scriptURL: $scriptURL, sourceLanguage: $sourceLanguage, targetLanguage: $targetLanguage, outputType: $outputType)
  }
`;
export { RUN_MT_INDEPENDENT };

const RUN_TTS_INDEPENDENT = gql`
  query($apiName: String, $scriptURL: String, $sourceLanguage: String, $outputType: String) {
    runTTSIndependent(apiName: $apiName, scriptURL: $scriptURL, sourceLanguage: $sourceLanguage, outputType: $outputType)
  }
`;
export { RUN_TTS_INDEPENDENT };

const GET_API_LIST = gql`
  query($tasktype: String, $sourcelang: String, $targetlang: String) {
    findAPIs(
      tasktype: $tasktype
      sourcelang: $sourcelang
      targetlang: $targetlang
    ) {
      APIName
    }
  }
`;
export { GET_API_LIST };

const UPLOAD_FILE = gql`
  mutation($file: Upload!) {
    uploadFile(file: $file) {
      filename
      mimetype
      encoding
      uri
    }
  }
`;
export { UPLOAD_FILE };

