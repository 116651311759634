import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import NativeSelect from "@material-ui/core/NativeSelect";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useLazyQuery, useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  GET_API_LIST,
  UPLOAD_FILE,
  GET_SOURCE_LANGUAGES,
  RUN_TTS_INDEPENDENT,
} from "../../../queries/TaskQueries";
import "../../../styles/loader.css";
import TTSData from "../../../jsons/TTSData.json";
import { Tooltip, Button, CircularProgress } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { useMemo } from "react";
import {
  NEW_TEMP_TASK,
  GET_TEMP_TASK_BY_ID,
} from "../../../queries/TempTaskQueires";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      fontSize: "clamp(8px, 1.5vh, 1.5vh)", //"calc(12px + 1.5vmin)",
      height: "12vh",
      maxHeight: "12vh",
    },
  },
  nativeInput: {
    padding: "10px 20px",
  },
  outlined: {
    paddingRight: 0,
  },
  noBorder: {
    border: "none",
  },
});

const TTS_LOAD = gql`
  query ($text: String, $source: String) {
    TTScard(text: $text, source: $source)
  }
`;

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "20px 0px",
  borderWidth: 1,
  // borderRadius: 2,
  width: "99.5%",
  height: "46.51%",
  borderColor: "#005893",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#005893",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function TTSCard(props) {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only("xs"));
  const isSM = useMediaQuery(theme.breakpoints.only("sm"));

  const setTTSValue = {};

  const classes = useStyles();

  const [TTSapi, setTTSapi] = useState(null);
  setTTSValue["api"] = setTTSapi;
  const [TTSsource, setTTSsource] = useState("Hindi");
  setTTSValue["source"] = setTTSsource;
  const [TTSInputText, setTTSInputText] = useState("");
  setTTSValue["inputText"] = setTTSInputText;
  const [inputChanged, setInputChanged] = useState(true);
  const [gender, setGender] = useState("female");

  const [showLoader, setShowLoader] = useState(false);
  const [errorStatement, setErrorStatement] = useState(null);

  const [helperText, setHelperText] = useState("");

  const [isDocumentMode, setIsDocumentMode] = useState(false);
  const [documentModeState, setDocumentModeState] = useState("selectFile");
  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [filename, setFileName] = useState(null);

  const [tempTaskCompleted, toggleTempTaskCompleted] = useState(false);
  const [tempTaskID, setTempTaskID] = useState();
  const [outputFileURL, setOutputFileURL] = useState();
  const [pollID, setPollID] = useState();

  let errorSection = (
    <Grid
      item
      container
      justify="center"
      alignItems="center"
      xs={12}
      style={{ height: "30px" }}
    >
      {errorStatement ? (
        <>
          <Grid item style={{ marginRight: 10 }}>
            <img src="images/Error.png" alt="err" />
          </Grid>
          <span
            style={{
              color: "#d01010",
              fontSize: "clamp(12px, 1.43vh, 1.43vh)",
            }}
          >
            {errorStatement}
          </span>
        </>
      ) : null}
    </Grid>
  );

  useEffect(() => {
    if (fileURL) {
      let filename = file.name.split(".");
      let filename1 = filename.reduce((result, currentValue, index, array1) => {
        if (index == array1.length - 1) return result + "ttsaudio." + "wav";
        else if (index == 0) return result + currentValue;
        else return result + "." + currentValue;
      }, "");

      setFileName(filename1);
      setDocumentModeState("translated");
    }
  }, [fileURL]);

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ noClick: true, noKeyboard: true });

  if (acceptedFiles.length && documentModeState == "selectFile") {
    setFile(acceptedFiles[0]);
    setDocumentModeState("fileSelected");
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [
    runTTSIndependent,
    {
      called: called_tts,
      loading: loading_tts,
      error: error_tts,
      data: data_tts,
    },
  ] = useLazyQuery(RUN_TTS_INDEPENDENT, {
    onCompleted: (data) => {
      var aurl = data.runTTSIndependent;
      setShowLoader(false);
      playAudio(aurl);
      setInputChanged(false);
      setHelperText("Click on speaker to play audio");
      if (isDocumentMode) setDocumentModeState("translated");
    },
    onError: (err) => {
      errorTTS(err);
      if (acceptedFiles.length) acceptedFiles.pop();
      setDocumentModeState("selectFile");
    },
  });

  const [
    getTempTask,
    { loading: fetchingTempTask, error: errorInTempTask, data: tempTask },
  ] = useLazyQuery(GET_TEMP_TASK_BY_ID, {
    fetchPolicy: "network-only",
    skip: !tempTaskID || (tempTaskID && tempTaskCompleted),
    variables: { tempTaskID: tempTaskID },
  });

  const startPollingTempTask = () => {
    const pollTempTask = setInterval(() => {
      getTempTask({ tempTaskID: tempTaskID });
    }, 5000);
    setPollID(pollTempTask);
  };

  const stopPollingTempTask = () => {
    clearInterval(pollID);
  };

  const [
    newTempTask,
    {
      loading: creatingNewTempTask,
      error: errorCreatingNewTempTask,
      data: newTempTaskData,
    },
  ] = useMutation(NEW_TEMP_TASK, {
    onCompleted: (data) => {
      let id = data.newTempTask._id;
      setTempTaskID(id);
      startPollingTempTask();
      setOutputFileURL(null);
    },
  });

  const [
    uploadFile,
    { loading: uploading, error: uploadError, data: fileDetails },
  ] = useMutation(UPLOAD_FILE, {
    onError: (err) => {
      errorTTS(err);
      if (acceptedFiles.length) acceptedFiles.pop();
      setDocumentModeState("selectFile");
    },
  });

  const {
    loading: loadingAPIs,
    error: errorLoadingAPIs,
    data: availableAPIs,
    refetch: refetchAPIs,
  } = useQuery(GET_API_LIST, {
    // variables: {
    //   tasktype: "TTS",
    //   sourcelang: "Hindi",
    // },
    onError: (err) => {
      errorTTS(err);
      if (acceptedFiles.length) acceptedFiles.pop();
      setDocumentModeState("selectFile");
    },
  });

  useEffect(() => {
    if (tempTask && tempTask.getTempTaskByID) {
      let status = tempTask.getTempTaskByID.Status;
      if (status === "Completed") {
        toggleTempTaskCompleted(true);
        stopPollingTempTask();
        let audioURL = tempTask.getTempTaskByID.OutputFileURL;
        setShowLoader(false);
        setInputChanged(false);
        setHelperText("Click on speaker to play audio");
        if (isDocumentMode) setDocumentModeState("translated");
        setOutputFileURL(audioURL);
      } else if (status === "Error") {
        console.log("TTS Failed!");
        stopPollingTempTask();
        setShowLoader(false);
        errorTTS("Error in TTS synthesis!");
        if (acceptedFiles.length) acceptedFiles.pop();
        setDocumentModeState("selectFile");
      }
    }
  }, [tempTask]);

  useEffect(() => {
    if (errorInTempTask) {
      let status = "Error in synthesis!";
      stopPollingTempTask();
      setShowLoader(false);
      errorTTS(errorInTempTask);
      if (acceptedFiles.length) acceptedFiles.pop();
      setDocumentModeState("selectFile");
    }
  }, [errorInTempTask]);

  useEffect(() => {
    if (availableAPIs && availableAPIs.findAPIs)
      setTTSapi(availableAPIs.findAPIs[0].APIName);
  }, [availableAPIs]);

  useEffect(() => {
    setInputChanged(true);
    setErrorStatement(null);
  }, [gender]);

  useEffect(() => {
    if (TTSInputText) setHelperText("Click on speaker to play audio");
    else setHelperText("");
  }, [TTSInputText]);

  useEffect(() => {
    setInputChanged(true);
    setErrorStatement(null);
    console.log("fetching apis again");
    refetchAPIs({
      tasktype: "TTS",
      sourcelang: TTSsource,
    });
  }, [TTSsource]);

  useEffect(() => {
    setInputChanged(true);
    setErrorStatement(null);
  }, [TTSapi]);

  useEffect(() => {
    if (fileDetails && fileDetails.uploadFile) {
      newTempTask({
        variables: {
          Type: "TTS",
          APIName: TTSapi,
          InputFileURL: fileDetails.uploadFile.uri,
          SourceLanguage: TTSsource,
          OutputType: "wav",
        },
      });
    }
  }, [fileDetails]);

  useEffect(() => {
    setErrorStatement(null);
  }, [isDocumentMode]);

  const handleChangeTTS = (inputFrom, e) => {
    e.preventDefault();
    setErrorStatement(null);
    setOutputFileURL("");
    setTTSValue[inputFrom](e.target.value);

    if (inputFrom == "inputText") setInputChanged(true);
  };

  function playAudio(url) {
    const audio = new Audio(url);
    audio.play();
  }

  const {
    called: calledSourceLanguages,
    loading: loadingSourceLanguages,
    error: errorloadingSource,
    data: sourceLanguages,
    // refetch: refetchTargetLanguages
  } = useQuery(GET_SOURCE_LANGUAGES, {
    variables: {
      taskType: "TTS",
    },
    onError: (err) => {
      errorTTS(err);
      if (acceptedFiles.length) acceptedFiles.pop();
      setDocumentModeState("selectFile");
    },
  });

  // TTS player
  const handleTTSPlay = async () => {
    if (inputChanged == true) {
      setShowLoader(true);
      setHelperText("Your text is being processed");

      const date = new Date();
      const tempFile = new File(
        [TTSInputText],
        "TTS" + date.toISOString() + ".txt"
      );

      uploadFile({
        variables: { file: tempFile },
      });
    }

    if (inputChanged != true && outputFileURL) {
      setShowLoader(false);
      playAudio(outputFileURL);
      setHelperText("Click on speaker to play audio");
    }
  };

  if (error_tts)
    console.log(
      "tts error",
      error_tts,
      "\n and other data is",
      called_tts,
      loading_tts,
      data_tts
    );

  let ButtonOrLoader = showLoader ? (
    <div className="loader" style={{ position: "relative" }}>
      <span className="stroke"></span>
      <span className="stroke"></span>
      <span className="stroke"></span>
      <span className="stroke"></span>
      <span className="stroke"></span>
      <span className="stroke"></span>
      <span className="stroke"></span>
    </div>
  ) : (
    <VolumeUpIcon
      style={{ height: "5.5vh", width: "5vh", color: "rgba(11, 94, 151, 1)" }}
    />
  );

  function errorTTS(err) {
    console.log("Error in tts is", err);
    if (err) {
      setHelperText("");
      setErrorStatement("Technical problem. Try again!");
      if (showLoader == true) setShowLoader(false);
    }
  }

  function toggleCardMode(mode) {
    const blueBackgroundTTS = document.getElementById("blueBackgroundTTS");
    const diff = blueBackgroundTTS.offsetHeight;

    document.getElementById(mode + "TTS").style.opacity = 0;
    document.getElementById(mode + "SelectedTTS").style.opacity = 1;
    if (mode == "Document") {
      document.getElementById("TextSelectedTTS").style.opacity = 0;
      document.getElementById("TextTTS").style.opacity = 1;

      document.getElementById("TextSelectedTTS").zIndex = -1;
      document.getElementById("TextTTS").style.zIndex = 1;
      document.getElementById("DocumentSelectedTTS").zIndex = 1;
      document.getElementById("DocumentTTS").style.zIndex = -1;

      blueBackgroundTTS.style.transform = "translate(" + -diff + "px, 0px)";
      setIsDocumentMode(true);
    } else if (mode == "Text") {
      document.getElementById("DocumentSelectedTTS").style.opacity = 0;
      document.getElementById("DocumentTTS").style.opacity = 1;

      document.getElementById("TextSelectedTTS").zIndex = 1;
      document.getElementById("TextTTS").style.zIndex = -1;
      document.getElementById("DocumentSelectedTTS").zIndex = -1;
      document.getElementById("DocumentTTS").style.zIndex = 1;

      blueBackgroundTTS.style.transform = "translate(" + 0 + "px, 0px)";
      setIsDocumentMode(false);
    }
  }

  function translateFile() {
    uploadFile({
      variables: { file: file },
    });
    setDocumentModeState("translating");
  }

  function closeFile() {
    acceptedFiles.pop();
    setDocumentModeState("selectFile");
    setFileURL(null);
  }

  function getFileSize(fileSize) {
    return fileSize < 1024
      ? fileSize + " bytes"
      : fileSize < 1024 * 1024
      ? (fileSize / 1024).toFixed(2) + "kb"
      : fileSize < 1024 * 1024 * 1024
      ? (fileSize / (1024 * 1024)).toFixed(2) + "mb"
      : (fileSize / (1024 * 1024 * 1024)).toFixed(2) + "gb";
  }

  let fileSize;
  if (acceptedFiles.length) {
    fileSize = getFileSize(acceptedFiles[0].size);
  }

  let buttons = (
    <>
      <IconButton disabled={showLoader} onClick={handleTTSPlay}>
        {ButtonOrLoader}
      </IconButton>
      {tempTaskCompleted && !inputChanged ? (
        <IconButton
          onClick={() =>
            window.open(tempTask.getTempTaskByID.OutputFileURL, "_blank")
          }
        >
          <GetAppIcon style={{ color: "rgb(11, 94, 151)", fontSize: "4vh" }} />
        </IconButton>
      ) : null}
    </>
  );

  const documentCode =
    documentModeState == "selectFile" ? (
      <>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          Drag 'n' drop file(txt, pdf or vtt)
          <div>or</div>
          <button
            type="button"
            onClick={open}
            style={{
              border: "none",
              backgroundColor: "#005893",
              color: "white",
              height: "2.6vh",
              minHeight: "20px",
            }}
          >
            Browse
          </button>
        </div>

        {errorSection ? errorSection : null}
      </>
    ) : documentModeState == "fileSelected" ? (
      <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          container
          justify="center"
          style={{ height: 35, color: "#7b7b7b" }}
        >
          <Grid item container justify="space-between" xs={8}>
            <Grid item>
              {documentModeState == "translated" ? (
                <span>File is ready</span>
              ) : null}
            </Grid>
            <Grid item container xs={1} justify="center" alignItems="center">
              <input
                type="image"
                src="images/Close.png"
                height="10"
                onClick={closeFile}
                style={{ zIndex: 10 }}
              />
            </Grid>
          </Grid>
          {documentModeState == "translated" ? <Grid item xs={1} /> : null}
        </Grid>
        <Grid item container justify="center">
          <Grid
            item
            container
            xs={8}
            style={{
              backgroundColor: "#e2f0fc",
              height: "52px",
              borderRadius: "0.6vh",
            }}
          >
            <a
              href={documentModeState == "fileSelected" ? "#" : fileURL}
              target="_blank"
              style={{ display: "flex", height: "100%", width: "inherit" }}
            >
              <Grid item container justify="center" alignItems="center" xs={2}>
                <img src="images/File.png" height="25" />
              </Grid>
              <Grid
                item
                container
                xs={10}
                style={{ height: isXS ? "100%" : "auto" }}
              >
                <Grid
                  item
                  container
                  direction={"column"}
                  justify="center"
                  style={{
                    height: isXS ? "100%" : "auto",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Grid
                    item
                    style={{
                      color: "#005893",
                      wordBreak: "break-all",
                      height: "auto",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        display: "inline-block",
                        height: 22,
                        width: 145,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      title={
                        documentModeState == "fileSelected"
                          ? file.name
                          : filename
                      }
                    >
                      {documentModeState == "fileSelected"
                        ? file.name
                        : filename}
                    </p>
                  </Grid>
                  {
                    <Grid item style={{ color: "#909090" }}>
                      {fileSize}
                    </Grid>
                  }
                </Grid>
              </Grid>
            </a>
          </Grid>
          {documentModeState == "translated" ? (
            <Grid item container xs={1} justify="center" alignItems="center">
              <a href={fileURL} download={filename}>
                <img src="images/Download.png" height="16" />
              </a>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={8}
          style={{ height: "52px", minHeight: "40px" }}
          justify="flex-end"
          alignItems="center"
        >
          {documentModeState == "translated" ? null : (
            <button
              style={{
                backgroundColor: "#005893",
                color: "white",
                height: "60%",
                border: "none",
                borderRadius: "4px",
              }}
              onClick={translateFile}
            >
              Synthesize
            </button>
          )}
        </Grid>
      </Grid>
    ) : documentModeState == "translating" ? (
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ height: "100%" }}
      >
        <Grid>
          <CircularProgress size={50} style={{ color: "#005893" }} />
        </Grid>
        <Grid style={{ color: "#005893" }}>
          &nbsp;&nbsp;&nbsp;Synthesizing....
        </Grid>
      </Grid>
    ) : documentModeState == "translated" ? (
      <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item container xs={8} justify="flex-end" alignItems="center">
          <input
            type="image"
            src="images/Close.png"
            height="10"
            onClick={closeFile}
            style={{ zIndex: 10 }}
          />
        </Grid>
        <Grid style={{ height: "100%" }}>{buttons}</Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <span style={{ color: "rgb(11, 94, 151)" }}>{helperText}</span>
        </Grid>
      </Grid>
    ) : null;

  return (
    <Grid item container>
      <Grid
        id="3"
        item
        container
        style={{
          minWidth: "267px",
          minHeight: "270px",
          width: isXS ? "80vw" : isSM ? "50vw" : "28vw",
          height: "38.33vh",
          animation: "compress-card .6s ease-out forwards",
        }}
        onClick={(e) => {
          props.gridClick(e);
        }}
      >
        <Grid
          item
          container
          style={{ width: "100%", height: "10.3%" }}
          direction="row"
          xs={12}
        >
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Tooltip
              title="TTS related details are here.TTS related details are here.TTS related details are here."
              placement="top-start"
            >
              <Button>Synthesize</Button>
            </Tooltip>
          </Grid>
          <Grid
            item
            container
            xs={6}
            justify="flex-end"
            style={{ height: "100%" }}
          >
            <Grid
              item
              container
              justify="flex-end"
              style={{ height: "inherit", position: "relative" }}
            >
              <div
                id="blueBackgroundTTS"
                style={{
                  height: "inherit",
                  width: document.getElementById("blueBackgroundTTS")
                    ? document.getElementById("blueBackgroundTTS").offsetHeight
                    : 0,
                  backgroundColor: "#005893",
                  position: "absolute",
                  right: 0,
                  zIndex: -2,
                }}
              ></div>
              <span
                style={{
                  overflow: "hidden",
                  display: "flex",
                  position: "relative",
                  width: document.getElementById("blueBackgroundTTS")
                    ? document.getElementById("blueBackgroundTTS").offsetHeight
                    : 0,
                  height: document.getElementById("blueBackgroundTTS")
                    ? document.getElementById("blueBackgroundTTS").offsetHeight
                    : 0,
                }}
              >
                <img
                  id="DocumentTTS"
                  src="images/Document.png"
                  alt="Doc"
                  style={{ position: "relative", bottom: 2, zIndex: 1 }}
                  onClick={() => toggleCardMode("Document")}
                />
                <img
                  id="DocumentSelectedTTS"
                  src="images/DocumentSelected.png"
                  alt="Doc"
                  style={{
                    position: "absolute",
                    height: "inherit",
                    opacity: 0,
                  }}
                />
              </span>
              <span
                style={{
                  display: "flex",
                  position: "relative",
                  width: document.getElementById("blueBackgroundTTS")
                    ? document.getElementById("blueBackgroundTTS").offsetHeight
                    : 0,
                  height: document.getElementById("blueBackgroundTTS")
                    ? document.getElementById("blueBackgroundTTS").offsetHeight
                    : 0,
                }}
              >
                <img
                  id="TextTTS"
                  src="images/Text.png"
                  alt="Txt"
                  style={{
                    height: "inherit",
                    opacity: 0,
                    position: "relative",
                    bottom: 2,
                  }}
                  onClick={() => toggleCardMode("Text")}
                />

                {/* <img id='TextTTS' src='images/Text.png' alt='Txt' 
                style={{position: 'relative', bottom: 2, zIndex: 1}}
                onClick={() => toggleCardMode("Document")}/> */}

                <img
                  id="TextSelectedTTS"
                  src="images/TextSelected.png"
                  alt="Txt"
                  style={{ height: "inherit", position: "absolute" }}
                />
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Paper
          id="TTSPaper"
          elevation={0}
          style={{
            width: "100%",
            height: "89.7%",
            borderRadius: "0px",
            border: "2px solid #0B5E97",
            marginBottom: "5%",
            pointerEvents: "none",
            opacity: "0.92",
          }}
        >
          <Grid
            container
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{ paddingBottom: "0px", height: "100%" }}
          >
            <Grid item container>
              <Grid
                item
                container
                xs={6}
                md={6}
                lg={6}
                xl={6}
                justify="flex-start"
              >
                <NativeSelect
                  className={`${classes.nativeInput} ${classes.outlined}`}
                  value={TTSsource}
                  size="small"
                  onChange={(e) => handleChangeTTS("source", e)}
                  disableUnderline={true}
                  style={{ color: "rgb(11, 94, 151)", maxWidth: 140 }}
                >
                  {sourceLanguages
                    ? sourceLanguages.getSourceLanguages.map((language) => (
                        <option value={language}>{language}</option>
                      ))
                    : null}
                </NativeSelect>
              </Grid>
              <Grid
                item
                container
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                justify="flex-end"
                style={{ paddingRight: "20px" }}
              >
                <NativeSelect
                  className={`${classes.nativeInput} ${classes.outlined}`}
                  value={TTSapi}
                  size="small"
                  onChange={(e) => handleChangeTTS("api", e)}
                  disableUnderline={true}
                  style={{ color: "rgb(11, 94, 151)", minWidth: 140 }}
                >
                  {availableAPIs &&
                    availableAPIs.findAPIs.map((api) => (
                      <option value={api.APIName}>{api.APIName}</option>
                    ))}
                </NativeSelect>
              </Grid>
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              style={{ width: "100%", height: "80%" }}
            >
              {isDocumentMode ? (
                documentCode
              ) : (
                <>
                  <Grid xs={12} md={12} lg={12} xl={12}>
                    <TextField
                      multiline
                      placeholder="Please Enter Some Text"
                      value={TTSInputText}
                      onChange={(e) => handleChangeTTS("inputText", e)}
                      variant="outlined"
                      rows={2}
                      rowsMax={2}
                      style={{
                        width: "100%",
                        backgroundColor: "rgba(240, 240, 240, 1)",
                      }}
                      InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                      }}
                      className={classes.root}
                    />
                  </Grid>
                  {errorSection ? errorSection : null}
                  <Grid
                    item
                    xs={12}
                    style={{ paddingBottom: "0vw", textAlign: "center" }}
                  >
                    {buttons}
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <span style={{ color: "rgb(11, 94, 151)" }}>
                      {helperText}
                    </span>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default TTSCard;
