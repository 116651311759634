import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Grid, IconButton, Fade } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import image1 from "../../../Static/UsertubeScreenshot1.jpg";
import image2 from "../../../Static/UsertubeScreenshot2.jpg";
import image3 from "../../../Static/UsertubeScreenshot3.jpg";
import image4 from "../../../Static/UsertubeScreenshot4.jpg";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ScreenshotModal from "./ScreenshotModal";

function getModalStyle() {
  const top = 50;
  const left = 40;

  return {
    top: `48.5%`,
    left: `50%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid white",
    boxShadow: theme.shadows[5],
    padding: "60px", //theme.spacing(3, 3, 3)
    width: 827,
    height: 427
  },
  screenshotPaper: {
    position: "absolute",
    width: 947,
    height: 547,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid white",
    boxShadow: theme.shadows[5]
    // padding: theme.spacing(3, 3, 3)
  }
}));

const AppModal = (props) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [screenshotImage, setScreenshotImage] = React.useState(undefined);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.setAppModalData(undefined);
  };

  if (props.app == null) {
    return <></>;
  } else if (open == false) setOpen(true);
  else if (screenshotImage) {
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.screenshotPaper}>
            <Grid container direction="column">
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <IconButton onClick={() => setScreenshotImage(undefined)}>
                  <CloseIcon style={{ title: "close", color: "#0000008c" }} />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ textAlign: "center", paddingTop: "5px" }}
              >
                <img
                  src={`images/${screenshotImage}`}
                  height="470px"
                  width="845px"
                  style={{ border: "1px solid #c1c1c1" }}
                />
              </Grid>
            </Grid>
          </div>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {/* <Fade in={open}> */}
          <div style={modalStyle} className={classes.paper}>
            <Grid container>
              <Grid item style={{ paddingRight: "24px" }}>
                <img
                  src={`images/${props.app.image}`}
                  alt="app image"
                  height="190px"
                  width="250px"
                />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: "36px" }}>
                <span id="simple-modal-title" style={{ fontSize: "30px" }}>
                  {props.app.name}
                </span>
                <p id="simple-modal-description">
                  {/* Duis mollis, est non commodo luctus, nisi erat porttitor ligula. Duis mollis, est non commodo luctus, nisi erat porttitor ligula.Duis mollis, est non commodo luctus, nisi erat porttitor ligula.Duis mollis, est non commodo luctus, nisi erat porttitor ligula. */}
                  {props.app.description}
                </p>
              </Grid>
              <Grid item style={{ alignSelf: "center", margin: "auto" }}>
                <a href={props.app.link}>
                  <OpenInNewIcon style={{ color: "rgba(0, 0, 0, 0.54)" }} />
                </a>
              </Grid>
              {/* <Grid item style={{alignSelf: "center"}} title={`Open in new tab`}>
                <a href={props.app.link} target="_blank">
                <IconButton >
                  <CloseIcon  style={{fontSize: "35px", title: "Open", color: "rgba(123, 123, 123, 0.3)"}}/>
                </IconButton>
                </a>
              </Grid> */}

              <Grid
                container
                xs={12}
                justify="flex start"
                style={{ marginTop: "40px" }}
              >
                <Grid item xs={12}>
                  <h2>Screenshots</h2>
                </Grid>

                {props.app.screenshots.map(function (image) {
                  return (
                    <Grid item>
                      <img
                        src={`images/${image}`}
                        onClick={() => setScreenshotImage(image)}
                        height="100px"
                        width="150px"
                        style={{ border: "1px solid grey", margin: "5px" }}
                      />
                    </Grid>
                  );
                })}
                {/* <Grid item>
                  <img src={image1} onClick={() => setScreenshotImage(image1)} height="100px" width="150px" style={{border: "1px solid grey", margin: "5px"}}/>
                </Grid>
                <Grid item>
                  <img src={image2} onClick={() => setScreenshotImage(image2)} height="100px" width="150px" style={{border: "1px solid grey", margin: "5px"}}/>
                </Grid>
                <Grid item>
                  <img src={image3} onClick={() => setScreenshotImage(image3)} height="100px" width="150px" style={{border: "1px solid grey", margin: "5px"}}/>
                </Grid>
                <Grid item>
                  <img src={image4} onClick={() => setScreenshotImage(image4)} height="100px" width="150px" style={{border: "1px solid grey", margin: "5px"}}/>
                </Grid> */}
              </Grid>
            </Grid>
            {/* <ScreenshotModal image={screenshotImage ? screenshotImage : null} setScreenshotImage={setScreenshotImage}/> */}
          </div>
          {/* </Fade> */}
        </Modal>
      </div>
    );
  }
};

export default AppModal;
