import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function Footer(props) {

    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));

    const contactGrid = (
        <Grid>
            <Typography variant='subtitle1' style={{color: '#005893'}}>
                Contact - tto@iiit.ac.in
            </Typography>
        </Grid>
    );

    return (
        <Grid container direction={isXS ? 'column' : 'row'} xs={12} style={{height: isXS ? '90px' : '70px', backgroundColor: '#ebf3fa'}} justify='center' alignItems='center'>


                    {/*
                    isXS
                    ?
                    (
                        <>
                     <Grid item container xs={6} justify='center' style={{color: '#005893'}}>
                        <Grid item container xs={1} direction='column' alignItems='flex-start'>
                            <Grid item style={{padding: '20px 0px'}}>
                                <Typography variant='subtitle2' style={{fontWeight: 'bold'}}>
                                    ABOUT
                                </Typography>
                            </Grid>
                            <Grid item style={{padding: '20px 0px'}}>
                                <Typography variant='subtitle2' style={{fontWeight: 'bold'}}>
                                    HELP
                                </Typography>
                            </Grid>
                            <Grid item style={{padding: '20px 0px'}}>
                                <Typography variant='subtitle2' style={{fontWeight: 'bold'}}>
                                    TERMS
                                </Typography>
                            </Grid>
                            <Grid item style={{padding: '20px 0px'}}>
                                <Typography variant='subtitle2' style={{fontWeight: 'bold'}}>
                                    GUIDELINES
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container xs={6} justify='center' style={{fontWeight: 'bold', color: '#005893'}}>
                        <Grid item container xs={8}  direction='column' alignItems='flex-start'>
                            <Grid item style={{padding: '20px 0px'}}>
                                <Typography variant='subtitle2' style={{fontWeight: 'bold'}}>
                                    TESTIMONIALS
                                </Typography>
                            </Grid>
                            <Grid item style={{padding: '20px 0px'}}>
                                <Typography variant='subtitle2' style={{fontWeight: 'bold'}}>
                                    ADVERTISE
                                </Typography>
                            </Grid>
                            <Grid item style={{padding: '20px 0px'}}>
                                <Typography variant='subtitle2' style={{fontWeight: 'bold'}}>
                                    INTEGRATIONS
                                </Typography>
                            </Grid>
                            <Grid item style={{padding: '20px 0px'}}>
                                <Typography variant='subtitle2' style={{fontWeight: 'bold'}}>
                                    CAREERS
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid> 
                    
                    //     </>
                    // )
                    // :
                    */}
                <>
                    {
                        isXS
                        ?
                        contactGrid
                        :
                        null
                    }
                    <Grid style={{paddingRight: isXS ? 0 : '20px'}}>
                        <Typography variant='subtitle1' style={{color: '#005893'}}>
                            Copyright <img src='/images/Copyright.png' alt='Copyright' height='15px' width='15px' /> 2021, IIITH. All rights reserved
                        </Typography>
                    </Grid>
                    <Grid item container style={{paddingRight: '20px', width: '4px', display: isXS ? 'none' : 'initial'}}>
                        <img src='/images/VerticalDivider.png' alt='|' height='30px' width='2px'/>
                    </Grid>
                    {isXS ? null : contactGrid}
                    {/* <Grid item container direction='column' xs={3} alignItems='center' style={{paddingTop: '30px'}}>
                    <Typography variant='h6' style={{color: '#005893'}}>
                        ABOUT
                    </Typography>
                    </Grid>
                    <Grid item container direction='column' xs={3} alignItems='center' style={{paddingTop: '30px'}}>
                    <Typography variant='h6' style={{color: '#005893'}}>
                        HELP
                    </Typography>
                    <Typography variant='h6' style={{color: '#005893'}}>
                        TERMS
                    </Typography>
                    <Typography variant='h6' style={{color: '#005893'}}>
                        GUIDELINES
                    </Typography>
                    </Grid>
                    <Grid item container direction='column' xs={3} alignItems='center' style={{paddingTop: '30px'}}>
                    <Typography variant='h6' style={{color: '#005893'}}>
                        TESTIMONIALS
                    </Typography>
                    <Typography variant='h6' style={{color: '#005893'}}>
                        ADVERTISE
                    </Typography>
                    <Typography variant='h6' style={{color: '#005893'}}>
                        INTEGRATIONS
                    </Typography>
                    </Grid>
                    <Grid item container direction='column' xs={3} alignItems='center' style={{paddingTop: '30px'}}>
                    <Typography variant='h6' style={{color: '#005893'}}>
                        CAREERS
                    </Typography>
                    </Grid> */}
                </>
        </Grid>
    );
}

export default Footer;