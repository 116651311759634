import gql from "graphql-tag";

export const GET_PIPELINES = gql`
  query {
    getPipelines(Application: "Canvas") {
      _id
      Name
      SourceLanguage
      TargetLanguage
    }
  }
`;

export const GET_PIPELINE_BY_ID = gql`
  query ($pipelineID: String) {
    getPipeline(PipelineID: $pipelineID) {
      Name
      TaskTemplates {
        _id
        Name
        Type {
          Type
        }
        API {
          APIName
        }
        SourceLanguage
        TargetLanguage
        OutputFileType
      }
      SourceLanguage
      TargetLanguage
      NumberOfTaskTemplates
    }
  }
`;
