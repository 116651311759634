import { variableStyles } from "./utilities/_variables";
import { spacingStyles } from "./utilities/_spacing";
import { landingStyles } from "./utilities/_landing";

const GlobalCss = ({ children }) => {
  variableStyles();
  spacingStyles();
  landingStyles();

  return children;
};

export default GlobalCss;
