import React, { createContext, useContext, useState, useEffect } from "react";

const WindowDimensions = createContext(null);

export const WindowDimensionsProvider = ({ children }) => {
  const [useHeight, setHeight] = useState(window.innerHeight);
  const [useWidth, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <WindowDimensions.Provider value={{ height: useHeight, width: useWidth }}>
      {children}
    </WindowDimensions.Provider>
  );
};

export const useWindowDimensions = () => useContext(WindowDimensions);
