import React, { useState, useMemo, useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import NativeSelect from "@material-ui/core/NativeSelect";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import { useLazyQuery, Mutation, useQuery, useMutation } from "react-apollo";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import gql from "graphql-tag";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { CircularProgress } from "@material-ui/core";
import { useDropzone } from "react-dropzone";

import "../../../styles/loader.css";
import { InputAdornment, IconButton } from "@material-ui/core";
import {
  GET_SOURCE_LANGUAGES,
  GET_API_LIST,
  UPLOAD_FILE,
} from "../../../queries/TaskQueries";
import {
  GET_TEMP_TASK_BY_ID,
  NEW_TEMP_TASK,
} from "../../../queries/TempTaskQueires";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      fontSize: "clamp(8px, 1.5vh, 1.5vh)", //"calc(12px + 1.5vmin)",
      height: "11vh",
      maxHeight: "11vh",
    },
  },
  textField: {
    padding: 5,
  },
  nativeInput: {
    padding: "10px 20px",
  },
  outlined: {
    paddingRight: 0,
  },
  noBorder: {
    border: "none",
  },
});

const MicRecorder = require("mic-recorder-to-mp3");

const FILE_UPLOAD = gql`
  mutation ($file: Upload) {
    uploadFile(file: $file, application: "UserTube") {
      filename
      mimetype
      encoding
      uri
    }
  }
`;

const RUN_ASR = gql`
  query (
    $apiName: String
    $scriptURL: String
    $sourceLanguage: String
    $outputType: String
  ) {
    runASRIndependent(
      apiName: $apiName
      scriptURL: $scriptURL
      sourceLanguage: $sourceLanguage
      outputType: $outputType
    )
  }
`;

const VTT_TO_TEXT = gql`
  query ($scriptURL: String) {
    getJSONScript(scriptURL: $scriptURL)
  }
`;

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-evenly",
  padding: "20px 0px",
  borderWidth: 1,
  // borderRadius: 2,
  width: "99.5%",
  height: "46.51%",
  borderColor: "#005893",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#005893",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function ASRCard(props) {
  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ noClick: true, noKeyboard: true });

  const [documentModeState, setDocumentModeState] = useState("selectFile");
  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [filename, setFileName] = useState(null);
  // const [inputFileType, setInputFileType] = useState(null);

  const [tempTaskCompleted, toggleTempTaskCompleted] = useState(false);
  const [tempTaskID, setTempTaskID] = useState();
  const [outputFileURL, setOutputFileURL] = useState();
  const [pollID, setPollID] = useState();

  useEffect(() => {
    if (file) {
      let outputArray = file.name.split(".");
      // setInputFileType(outputArray[outputArray.length - 1]);
    }
  }, [file]);

  const [
    runASR,
    {
      called: is_called,
      loading: loading_asr,
      error: error_asr,
      data: data_asr,
    },
  ] = useLazyQuery(RUN_ASR, {
    onCompleted: (data) => {
      if (isDocumentMode) {
        var xhr = new XMLHttpRequest(),
          a = document.createElement("a"),
          file;

        xhr.open("GET", data.runASRIndependent, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
          file = new Blob([xhr.response], {
            type: "text/plain",
          });
          let temp = URL.createObjectURL(file);
          setFileURL(temp);
          setDocumentModeState("translated");
          a.click();
        };
        xhr.send();
      } else {
        vttToText({
          variables: {
            scriptURL: data.runASRIndependent,
          },
        });
      }
    },
    onError: (err) => {
      errorASR(err);
      if (acceptedFiles.length) acceptedFiles.pop();
      setDocumentModeState("selectFile");
    },
  });

  const [
    uploadFile,
    { loading: uploading, error: uploadError, data: fileDetails },
  ] = useMutation(UPLOAD_FILE, {
    onCompleted: (data) => {
      setFileURL(null);
      newTempTask({
        variables: {
          Type: "ASR",
          APIName: ASRapi,
          InputFileURL: data.uploadFile.uri,
          SourceLanguage: ASRsource,
          OutputType: "vtt",
        },
      });
    },
    onError: (err) => {
      errorASR(err);
      if (acceptedFiles.length) acceptedFiles.pop();
      setDocumentModeState("selectFile");
    },
  });

  const [
    getTempTask,
    { loading: fetchingTempTask, error: errorInTempTask, data: tempTask },
  ] = useLazyQuery(GET_TEMP_TASK_BY_ID, {
    fetchPolicy: "network-only",
    skip: !tempTaskID || (tempTaskID && tempTaskCompleted),
    variables: { tempTaskID: tempTaskID },
  });

  const startPollingTempTask = () => {
    const pollTempTask = setInterval(() => {
      getTempTask({ tempTaskID: tempTaskID });
    }, 5000);
    setPollID(pollTempTask);
  };

  const stopPollingTempTask = () => {
    clearInterval(pollID);
  };

  const [
    newTempTask,
    {
      loading: creatingNewTempTask,
      error: errorCreatingNewTempTask,
      data: newTempTaskData,
    },
  ] = useMutation(NEW_TEMP_TASK, {
    onCompleted: (data) => {
      let id = data.newTempTask._id;
      setTempTaskID(id);
      startPollingTempTask();
      setOutputFileURL(null);
    },
  });

  useEffect(() => {
    if (tempTask && tempTask.getTempTaskByID) {
      let status = tempTask.getTempTaskByID.Status;
      if (tempTask.getTempTaskByID._id == tempTaskID) {
        if (status === "Completed") {
          toggleTempTaskCompleted(true);
          stopPollingTempTask();

          if (isDocumentMode) {
            var xhr = new XMLHttpRequest(),
              a = document.createElement("a"),
              file;

            xhr.open("GET", tempTask.getTempTaskByID.OutputFileURL, true);
            xhr.responseType = "blob";
            xhr.onload = function () {
              file = new Blob([xhr.response], {
                type: "text/plain",
              });
              let temp = URL.createObjectURL(file);
              setFileURL(temp);
              setDocumentModeState("translated");
              a.click();
            };
            xhr.send();
          } else {
            vttToText({
              variables: {
                scriptURL: tempTask.getTempTaskByID.OutputFileURL,
              },
            });
          }
        } else if (status === "Error") {
          console.log("TTS Failed!");
          stopPollingTempTask();
          setShowLoader(false);
          errorASR("Error in MT!");
          if (acceptedFiles.length) acceptedFiles.pop();
          setDocumentModeState("selectFile");
        }
      }
    }
  }, [tempTask]);

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only("xs"));
  const isSM = useMediaQuery(theme.breakpoints.only("sm"));
  const classes = useStyles();
  const setASRValue = {};

  const [ASRapi, setASRapi] = useState("Mashinga ASR");
  setASRValue["api"] = setASRapi;
  const [ASRsource, setASRsource] = useState("English");
  setASRValue["source"] = setASRsource;

  const [ASRText, setASRText] = useState("");

  const [clearData, setClearData] = useState(false);

  const [RecordingStatus, setRecordingStatus] = useState(MicIcon);

  const [mashingaUploadedURL, setMashingaUploadedURL] = React.useState("");

  const [showLoader, setShowLoader] = useState(false);

  const [errorStatement, setErrorStatement] = useState(null);

  const [helperText, setHelperText] = useState(
    "Click on the mic to start recording"
  );

  const [isDocumentMode, setIsDocumentMode] = useState(false);

  let errorSection = (
    <Grid
      item
      container
      justify="center"
      alignItems="center"
      xs={12}
      style={{ height: "30px" }}
    >
      {errorStatement ? (
        <>
          <Grid item style={{ marginRight: 10 }}>
            <img src="images/Error.png" alt="err" />
          </Grid>
          <span
            style={{
              color: "#d01010",
              fontSize: "clamp(12px, 1.43vh, 1.43vh)",
            }}
          >
            {errorStatement}
          </span>
        </>
      ) : null}
    </Grid>
  );

  useEffect(() => {
    setASRText("");
    setErrorStatement(null);
  }, [ASRsource]);

  useEffect(() => {
    setErrorStatement(null);
  }, [ASRapi]);

  useEffect(() => {
    if (fileURL) {
      let filename = file.name.split(".");
      let filename1 = filename.reduce((result, currentValue, index, array1) => {
        if (index == array1.length - 1) return result + "asrscript." + "vtt";
        else if (index == 0) return result + currentValue;
        else return result + "." + currentValue;
      }, "");

      setFileName(filename1);
      setDocumentModeState("translated");
    }
  }, [fileURL]);

  if (acceptedFiles.length && documentModeState == "selectFile") {
    setFile(acceptedFiles[0]);
    setDocumentModeState("fileSelected");
  }

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const copyTextToClipboard = (event) => {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = ASRText;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  let ButtonOrLoader;

  const [
    vttToText,
    {
      called: is_called_vtt,
      loading: loading_txt,
      error: error_txt,
      data: data_txt,
    },
  ] = useLazyQuery(VTT_TO_TEXT, {
    onCompleted: (data) => {
      let data1 = JSON.parse(data.getJSONScript);

      const tempText = data1.cues.reduce(function (result, cue) {
        return result.concat(cue.text, " ");
      }, "");

      setASRText(tempText);
      setShowLoader(false);
      setHelperText("Click on the mic to start recording");
    },
    onError: (err) => errorASR(err),
  });

  const {
    // loading: loadingSource,
    // error: errorSource,
    data: dataSource,
  } = useQuery(GET_SOURCE_LANGUAGES, {
    variables: {
      taskType: "ASR",
    },
    onError: (err) => {
      errorASR(err);
      if (acceptedFiles.length) acceptedFiles.pop();
      setDocumentModeState("selectFile");
    },
  });

  const {
    loading: loadingAPIs,
    error: errorLoadingAPIs,
    data: availableAPIs,
    refetch: refetchAPIs,
  } = useQuery(GET_API_LIST, {
    variables: {
      tasktype: "ASR",
      sourcelang: "English",
    },
    onError: (err) => {
      errorASR(err);
      if (acceptedFiles.length) acceptedFiles.pop();
      setDocumentModeState("selectFile");
    },
  });

  useEffect(() => {
    if (availableAPIs && availableAPIs.findAPIs)
      setASRapi(availableAPIs.findAPIs[0].APIName);
  }, [availableAPIs]);

  useEffect(() => {
    setErrorStatement(null);
  }, [isDocumentMode]);

  //Event handlers
  const handleChangeASR = (e) => {
    e.preventDefault();
    setErrorStatement(null);
    setASRValue[e.target.id](e.target.value);
  };

  // ASRText function
  const handleASRText = (fileURL) => {
    setMashingaUploadedURL(fileURL);
    setClearData(true);
    setErrorStatement(null);
    setFileURL(null);
    newTempTask({
      variables: {
        Type: "ASR",
        APIName: ASRapi,
        InputFileURL: fileURL,
        SourceLanguage: ASRsource,
        OutputType: "vtt",
      },
    });
  };

  const recorder = useMemo(
    () =>
      new MicRecorder({
        bitRate: 128,
      }),
    []
  );

  if (error_asr)
    console.log(
      "asr error",
      error_asr,
      "\n and other data is",
      is_called,
      loading_asr,
      data_asr
    );

  if (error_txt)
    console.log(
      "error vtt",
      error_txt,
      "\n and other data",
      is_called_vtt,
      loading_txt,
      data_txt
    );

  if (showLoader) {
    ButtonOrLoader = (
      <div className="loader" style={{ position: "relative" }}>
        <span className="stroke"></span>
        <span className="stroke"></span>
        <span className="stroke"></span>
        <span className="stroke"></span>
        <span className="stroke"></span>
        <span className="stroke"></span>
        <span className="stroke"></span>
      </div>
    );
  } else {
    ButtonOrLoader = (
      <RecordingStatus
        style={{ height: "5.5vh", width: "5vh", color: "rgba(11, 94, 151, 1)" }}
      />
    );
  }

  function errorASR(err) {
    console.log("Error in asr is", err);
    if (err) {
      setHelperText("Click on the mic to start recording");
      setErrorStatement("Technical error. Try again!");
      if (showLoader == true) setShowLoader(false);
    }
  }

  function toggleCardMode(mode) {
    const blueBackgroundASR = document.getElementById("blueBackgroundASR");
    const diff = blueBackgroundASR.offsetHeight;

    document.getElementById(mode + "ASR").style.opacity = 0;
    document.getElementById(mode + "SelectedASR").style.opacity = 1;
    if (mode == "Document") {
      document.getElementById("TextSelectedASR").style.opacity = 0;
      document.getElementById("TextASR").style.opacity = 1;

      document.getElementById("TextSelectedASR").zIndex = -1;
      document.getElementById("TextASR").style.zIndex = 1;
      document.getElementById("DocumentSelectedASR").zIndex = 1;
      document.getElementById("DocumentASR").style.zIndex = -1;

      blueBackgroundASR.style.transform = "translate(" + -diff + "px, 0px)";
      setIsDocumentMode(true);
    } else if (mode == "Text") {
      document.getElementById("DocumentSelectedASR").style.opacity = 0;
      document.getElementById("DocumentASR").style.opacity = 1;

      document.getElementById("TextSelectedASR").zIndex = 1;
      document.getElementById("TextASR").style.zIndex = -1;
      document.getElementById("DocumentSelectedASR").zIndex = -1;
      document.getElementById("DocumentASR").style.zIndex = 1;

      blueBackgroundASR.style.transform = "translate(" + 0 + "px, 0px)";

      setIsDocumentMode(false);
    }
  }

  function translateFile() {
    uploadFile({
      variables: { file: file },
    });
    setDocumentModeState("translating");
  }

  function closeFile() {
    acceptedFiles.pop();
    setDocumentModeState("selectFile");
    setFileURL(null);
  }

  function getFileSize(fileSize) {
    return fileSize < 1024
      ? fileSize + " bytes"
      : fileSize < 1024 * 1024
      ? (fileSize / 1024).toFixed(2) + "kb"
      : fileSize < 1024 * 1024 * 1024
      ? (fileSize / (1024 * 1024)).toFixed(2) + "mb"
      : (fileSize / (1024 * 1024 * 1024)).toFixed(2) + "gb";
  }

  let fileSize;
  if (acceptedFiles.length) {
    fileSize = getFileSize(acceptedFiles[0].size);
  }

  const documentCode =
    documentModeState == "selectFile" ? (
      <>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          Drag 'n' drop file
          <div>or</div>
          <button
            type="button"
            onClick={() => {
              open();
              setErrorStatement(null);
            }}
            style={{
              border: "none",
              backgroundColor: "#005893",
              color: "white",
              height: "2.6vh",
              minHeight: "20px",
            }}
          >
            Browse
          </button>
        </div>
        {errorSection ? errorSection : null}
      </>
    ) : documentModeState == "fileSelected" ? (
      <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          container
          justify="center"
          style={{ height: 35, color: "#7b7b7b" }}
        >
          <Grid item container justify="space-between" xs={8}>
            <Grid item>
              {documentModeState == "translated" ? (
                <span>File is ready</span>
              ) : null}
            </Grid>
            <Grid item container xs={1} justify="center" alignItems="center">
              <input
                type="image"
                src="images/Close.png"
                height="10"
                onClick={closeFile}
                style={{ zIndex: 10 }}
              />
            </Grid>
          </Grid>
          {documentModeState == "translated" ? <Grid item xs={1} /> : null}
        </Grid>
        <Grid item container justify="center">
          <Grid
            item
            container
            xs={8}
            style={{
              backgroundColor: "#e2f0fc",
              height: "52px",
              borderRadius: "0.6vh",
            }}
          >
            <a
              href={documentModeState == "fileSelected" ? "#" : fileURL}
              target="_blank"
              style={{ display: "flex", height: "100%", width: "inherit" }}
            >
              <Grid item container justify="center" alignItems="center" xs={2}>
                <img src="images/File.png" height="25" />
              </Grid>
              <Grid
                item
                container
                xs={10}
                style={{ height: isXS ? "100%" : "auto" }}
              >
                <Grid
                  item
                  container
                  direction={"column"}
                  justify="center"
                  style={{
                    height: isXS ? "100%" : "auto",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Grid
                    item
                    style={{
                      color: "#005893",
                      wordBreak: "break-all",
                      height: "auto",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        display: "inline-block",
                        height: 22,
                        width: 145,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      title={
                        documentModeState == "fileSelected"
                          ? file.name
                          : filename
                      }
                    >
                      {documentModeState == "fileSelected"
                        ? file.name
                        : filename}
                    </p>
                  </Grid>
                  {
                    <Grid item style={{ color: "#909090" }}>
                      {fileSize}
                    </Grid>
                  }
                </Grid>
              </Grid>
            </a>
          </Grid>
          {documentModeState == "translated" ? (
            <Grid item container xs={1} justify="center" alignItems="center">
              <a href={fileURL} download={filename}>
                <img src="images/Download.png" height="16" />
              </a>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          xs={8}
          style={{ height: "52px", minHeight: "40px" }}
          justify="flex-end"
          alignItems="center"
        >
          {documentModeState == "translated" ? null : (
            <button
              style={{
                backgroundColor: "#005893",
                color: "white",
                height: "60%",
                border: "none",
                borderRadius: "4px",
              }}
              onClick={translateFile}
            >
              Transcribe
            </button>
          )}
        </Grid>
        {/* </Grid> */}
      </Grid>
    ) : documentModeState == "translating" ? (
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ height: "100%" }}
      >
        <Grid>
          <CircularProgress size={50} style={{ color: "#005893" }} />
        </Grid>
        <Grid style={{ color: "#005893" }}>
          &nbsp;&nbsp;&nbsp;Transcribing....
        </Grid>
      </Grid>
    ) : documentModeState == "translated" ? (
      <>
        <Grid item container alignItems="center" justify="center">
          <Grid
            item
            container
            xs={8}
            justify="flex-end"
            alignItems="center"
            style={{ height: 35 }}
          >
            <input
              type="image"
              src="images/Close.png"
              height="10"
              onClick={closeFile}
              style={{ zIndex: 10 }}
            />
          </Grid>
          <Grid
            item
            container
            justify="center"
            xs={8}
            style={{
              backgroundColor: "#e2f0fc",
              height: "52px",
              borderRadius: "0.6vh",
            }}
          >
            <a
              href={documentModeState == "fileSelected" ? "#" : fileURL}
              target="_blank"
              style={{ display: "flex", height: "100%", width: "inherit" }}
            >
              <Grid item container justify="center" alignItems="center" xs={2}>
                <img src="images/File.png" height="25" />
              </Grid>
              <Grid
                item
                container
                xs={10}
                style={{ height: isXS ? "100%" : "auto" }}
              >
                <Grid
                  item
                  container
                  direction={"column"}
                  justify="center"
                  style={{
                    height: isXS ? "100%" : "auto",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Grid
                    item
                    style={{
                      color: "#005893",
                      wordBreak: "break-all",
                      height: "auto",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        display: "inline-block",
                        height: 22,
                        width: 145,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      title={
                        documentModeState == "fileSelected"
                          ? file.name
                          : filename
                      }
                    >
                      {documentModeState == "fileSelected"
                        ? file.name
                        : filename}
                    </p>
                  </Grid>
                  {
                    <Grid item style={{ color: "#909090" }}>
                      {fileSize}
                    </Grid>
                  }
                </Grid>
              </Grid>
            </a>
          </Grid>
          {documentModeState == "translated" ? (
            <Grid item container xs={1} justify="center" alignItems="center">
              <a href={fileURL} download={filename}>
                <img src="images/Download.png" height="16" />
              </a>
            </Grid>
          ) : null}
        </Grid>
      </>
    ) : null;

  return (
    <Grid item container xs={12}>
      <Grid
        id="0"
        item
        container
        style={{
          minWidth: "267px",
          minHeight: "270px",
          width: isXS ? "90vw" : isSM ? "50vw" : "28vw",
          height: "38.33vh",
          animation: "compress-card .6s ease-out forwards",
        }}
        justify="flex-end"
        onClick={(e) => {
          props.gridClick(e);
        }}
      >
        <Grid
          item
          container
          xs={12}
          style={{ height: "10.3%", width: isXS ? "95%" : "100%" }}
        >
          <Grid item container xs={6} style={{ height: "100%" }}>
            <Tooltip
              title="ASR related details are here.ASR related details are here.ASR related details are here."
              placement="top-start"
            >
              <Button>Transcribe</Button>
            </Tooltip>
          </Grid>
          <Grid
            item
            container
            xs={6}
            justify="flex-end"
            style={{ height: "100%" }}
          >
            <Grid
              item
              container
              justify="flex-end"
              style={{ height: "inherit", position: "relative" }}
            >
              <div
                id="blueBackgroundASR"
                style={{
                  height: "inherit",
                  width: document.getElementById("blueBackgroundASR")
                    ? document.getElementById("blueBackgroundASR").offsetHeight
                    : 0,
                  backgroundColor: "#005893",
                  position: "absolute",
                  right: 0,
                  zIndex: -2,
                }}
              ></div>
              <span
                style={{
                  display: "flex",
                  position: "relative",
                  width: document.getElementById("blueBackgroundASR")
                    ? document.getElementById("blueBackgroundASR").offsetHeight
                    : 0,
                  height: document.getElementById("blueBackgroundASR")
                    ? document.getElementById("blueBackgroundASR").offsetHeight
                    : 0,
                }}
              >
                <img
                  id="DocumentASR"
                  src="images/Document.png"
                  alt="Doc"
                  style={{ position: "relative", bottom: 2, height: "inherit" }}
                  onClick={() => toggleCardMode("Document")}
                />
                <img
                  id="DocumentSelectedASR"
                  src="images/DocumentSelected.png"
                  alt="Doc"
                  style={{
                    position: "absolute",
                    height: "inherit",
                    opacity: 0,
                    zIndex: -1,
                  }}
                />
              </span>
              <span
                style={{
                  display: "flex",
                  position: "relative",
                  width: document.getElementById("blueBackgroundASR")
                    ? document.getElementById("blueBackgroundASR").offsetHeight
                    : 0,
                  height: document.getElementById("blueBackgroundASR")
                    ? document.getElementById("blueBackgroundASR").offsetHeight
                    : 0,
                }}
              >
                <img
                  id="TextASR"
                  src="images/Text.png"
                  alt="Txt"
                  style={{
                    position: "relative",
                    bottom: 2,
                    height: "inherit",
                    opacity: 0,
                  }}
                  onClick={() => toggleCardMode("Text")}
                />
                <img
                  id="TextSelectedASR"
                  src="images/TextSelected.png"
                  alt="Txt"
                  style={{ height: "inherit", position: "absolute" }}
                />
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Paper
          elevation={0}
          style={{
            width: isXS ? "95%" : "100%",
            height: "89.7%",
            border: "2px solid #0B5E97",
            borderRadius: "0px",
            marginBottom: "5%",
            pointerEvents: "none",
            opacity: "0.92",
          }}
          onClick={(e) => props.gridClick(e)}
        >
          <Grid
            container
            direction="column"
            justify="flex-start"
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{ height: "100%" }}
          >
            <Grid item container>
              {/*source language*/}
              <Grid
                item
                container
                xs={6}
                md={6}
                lg={6}
                xl={6}
                justify="flex-start"
              >
                <NativeSelect
                  className={`${classes.nativeInput} ${classes.outlined}`}
                  name="source"
                  id="source"
                  value={ASRsource}
                  onChange={handleChangeASR}
                  disableUnderline={true}
                  style={{ color: "rgb(11, 94, 151)" }}
                >
                  {dataSource
                    ? dataSource.getSourceLanguages.map((language) => (
                        <option value={language}>{language}</option>
                      ))
                    : null}
                </NativeSelect>
              </Grid>

              {/*apis*/}
              <Grid
                item
                container
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                justify="flex-end"
                style={{ width: "100%", paddingRight: "20px" }}
              >
                <NativeSelect
                  className={`${classes.nativeInput} ${classes.outlined}`}
                  id="api"
                  value={ASRapi}
                  size="small"
                  onChange={handleChangeASR}
                  disableUnderline={true}
                  style={{ color: "rgb(11, 94, 151)", minWidth: 160 }}
                >
                  {availableAPIs &&
                    availableAPIs.findAPIs.map((api) => (
                      <option
                        value={api.APIName}
                        style={{
                          textAlign: "center",
                          letterSpacing: "2px",
                          cursor: "pointer",
                          backgroundColor: "rgba(222, 222, 222, 0.7)",
                          marginBottom: "3px",
                        }}
                      >
                        {api.APIName}
                      </option>
                    ))}
                </NativeSelect>
              </Grid>
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justify="center"
              style={{ height: "72%" }}
            >
              {isDocumentMode ? (
                documentCode
              ) : (
                <>
                  <Mutation mutation={FILE_UPLOAD} onError={errorASR}>
                    {(
                      uploadFile,
                      {
                        called: called_upload,
                        data: data_upload,
                        loading: loading_upload,
                        error: error_upload,
                      }
                    ) => {
                      if (error_upload)
                        console.log(
                          "upload error",
                          error_upload,
                          "\n and other data",
                          called_upload,
                          loading_upload,
                          data_upload
                        );

                      if (clearData == true) {
                        data_upload = undefined;
                        setClearData(false);
                      }

                      try {
                        if (
                          data_upload &&
                          mashingaUploadedURL != data_upload.uploadFile.uri
                        ) {
                          handleASRText(data_upload.uploadFile.uri);
                        }
                      } catch (err) {
                        console.log("error is", err);
                        setShowLoader(false);
                      }

                      return (
                        <Grid container justify="center">
                          <Grid item xs={12} style={{ textAlign: "center" }}>
                            <form encType={"multipart/form-data"} method="post">
                              <Button
                                onClick={() => {
                                  if (RecordingStatus === MicIcon) {
                                    recorder
                                      .start()
                                      .then(() => {
                                        setErrorStatement(null);
                                        setRecordingStatus(StopIcon);
                                        setHelperText(
                                          "Click on the icon to stop recording"
                                        );
                                      })
                                      .catch((err) => {
                                        console.error(
                                          "Error in recording is...",
                                          err
                                        );
                                        setHelperText(
                                          "Click on the mic to start recording"
                                        );
                                      });
                                  } else {
                                    recorder
                                      .stop()
                                      .getMp3()
                                      .then(([buffer, blob]) => {
                                        setShowLoader(true);
                                        setHelperText(
                                          "Your audio is being scripted"
                                        );
                                        // do what ever you want with buffer and blob
                                        // Example: Create a mp3 file and play
                                        const file = new File(
                                          buffer,
                                          "me-at-thevoice.mp3",
                                          {
                                            type: blob.type,
                                            lastModified: Date.now(),
                                          }
                                        );

                                        uploadFile({
                                          variables: {
                                            file: file,
                                          },
                                        });
                                      })
                                      .catch((e) => {
                                        setHelperText(
                                          "Click on the mic to start recording"
                                        );
                                        alert(
                                          "We could not retrieve your message"
                                        );
                                        console.log(e);
                                      });
                                    setRecordingStatus(MicIcon);
                                    setHelperText(
                                      "Click on the mic to start recording"
                                    );
                                  }
                                }}
                                disabled={showLoader}
                                style={{ position: "relative", width: "24px" }}
                              >
                                {ButtonOrLoader}
                              </Button>
                            </form>
                          </Grid>
                        </Grid>
                      );
                    }}
                  </Mutation>

                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <span
                      style={{
                        color: "rgb(11, 94, 151)",
                        fontSize: "calc(12px + 0.4vw)",
                      }}
                    >
                      {helperText}
                    </span>
                  </Grid>

                  {errorSection ? errorSection : null}

                  <Grid xs={12} md={12} lg={12} xl={12}>
                    <TextField
                      multiline
                      value={ASRText}
                      variant="outlined"
                      rows={2}
                      rowsMax={2}
                      style={{
                        width: "100%",
                        backgroundColor: "rgba(240, 240, 240, 1)",
                        border: 0,
                        marginBottom: 10,
                      }}
                      className={classes.root}
                      InputProps={{
                        readOnly: true,
                        classes: { notchedOutline: classes.noBorder },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={copyTextToClipboard}>
                              <FileCopyOutlinedIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ASRCard;
