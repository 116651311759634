import gql from "graphql-tag";

export const NEW_TEMP_TASK = gql`
  mutation (
    $Type: String!
    $APIName: String!
    $SourceLanguage: String!
    $TargetLanguage: String
    $InputFileURL: String!
    $OutputType: String!
  ) {
    newTempTask(
      Type: $Type
      APIName: $APIName
      SourceLanguage: $SourceLanguage
      TargetLanguage: $TargetLanguage
      InputFileURL: $InputFileURL
      OutputType: $OutputType
    ) {
      _id
      Type
      APIName
      SourceLanguage
      TargetLanguage
      InputFileURL
      OutputFileURL
      Status
      TimeCreated
    }
  }
`;

export const GET_TEMP_TASK_BY_ID = gql`
  query ($tempTaskID: String!) {
    getTempTaskByID(tempTaskID: $tempTaskID) {
      _id
      Type
      APIName
      SourceLanguage
      TargetLanguage
      InputFileURL
      OutputFileURL
      Status
      TimeCreated
    }
  }
`;
