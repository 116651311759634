import React, { useState, useEffect }from "react";
import Grid from '@material-ui/core/Grid';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
    scrollDiv: {
        height: '8vh',
        marginTop: '0vh',
        overflow: 'auto',
        '&::-webkit-scrollbar-track': {
            WebkitBoxShadow: 'none !important',
            backgroundColor: 'transparent'
        },

        '&::-webkit-scrollbar': {
            width: '3px !important',
            backgroundColor: 'transparent'
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent'
        }
    }
})

const Credits = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.only('xs'));
    const [creditNumber, setCreditNumber] = useState(0);

    var keys = {37: 1, 38: 1, 39: 1, 40: 1};

    function preventDefault(e) {
    e.preventDefault();
    }

    function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
    }

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    var supportsPassive = false;

    useEffect(() => {
        let logos = document.getElementById('logos');

        logos.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
        logos.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
        logos.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
        logos.addEventListener('keydown', preventDefaultForScrollKeys, false);
    }, []);

    document.querySelector('html').style.scrollBehavior = 'smooth';

    // setTimeout(() => {
    //     // document.getElementById('logos').scrollLeft = 200;
    //     let logos = document.getElementById('logos');
    //     console.log('logos', logos);
    //     sideScroll(logos, 'right', 25, 76, 10);

    // }, 5000);

    function sideScroll(element,direction,speed,distance,step){
        let scrollAmount = 0;
        let slideTimer = setInterval(function(){
            if(direction == 'left'){
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if(scrollAmount >= distance){
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    if(isXS) {
        setInterval(() => {
            let logos = document.getElementById('logos');
            if(logos.scrollLeft ==  logos.childNodes[0].offsetWidth * 3) {
                sideScroll(logos, 'left', 5, logos.childNodes[0].offsetWidth * 3, 10);
            } else {
                sideScroll(logos, 'right', 20, logos.childNodes[0].offsetWidth, 10);
            }
        }, 5000);
    }

    return (
        <Grid item container justify='center' alignItems='center' style={{width: '100vw', height: '18vh'}}>
            <Grid item container justify='center' alignItems='center' style={{scrollBehavior: 'smooth', marginTop: '2.96vh', color: '#005893', height: '2.5vh', fontSize: '1.95vh'}}>
                Technology Partners
            </Grid>
            <Grid item container id='logos' wrap='nowrap' justify={isXS ? 'flex-start' : 'center'}  
            className={classes.scrollDiv}
            // style={{height: '7vh', overflow: 'auto'}}
            >
            {
                isXS
                ?
                (
                    <>
                        <div style={{display: 'flex', width: '100vw'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50vw'}}>
                                <img src='/images/IITMadras.png' alt='IIT Madras' style={{ padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50vw'}}>
                                <img src='/images/IITPatna.png' alt='IIT Patna' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                            </div>
                        </div>
                        <div style={{display: 'flex', width: '100vw'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50vw'}}>
                                <img src='/images/CDAC.png' alt='CDAC' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50vw'}}>
                                <img src='/images/IITBombay.png' alt='IIT Bombay' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                            </div>
                        </div>
                        <div style={{display: 'flex', width: '100vw'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50vw'}}>
                                <img src='/images/IIIT.png' alt='IIIT Hyderabad' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50vw'}}>
                                <img src='/images/ICFOSS.png' alt='ICFOSS' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                            </div>
                        </div>
                        <div style={{display: 'flex', width: '100vw'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50vw'}}>
                                <img src='/images/UH.png' alt='University of Hyderabad' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50vw'}}>
                                <img id='logo8' src='/images/AU.png' alt='Anna University' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                            </div>
                        </div>
                    </>
                )
                
                // isXS
                // ?
                // (
                //     <marquee>
                //         <img src='/images/IITMadras.png' alt='IIT Madras' style={{ padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                        
                //         <img src='/images/IITPatna.png' alt='IIT Patna' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                        
                //         <img src='/images/CDAC.png' alt='CDAC' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                        
                //         <img src='/images/IITBombay.png' alt='IIT Bombay' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                        
                //         <img src='/images/IIIT.png' alt='IIIT Hyderabad' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                        
                //         <img src='/images/ICFOSS.png' alt='ICFOSS' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                        
                //         <img src='/images/UH.png' alt='University of Hyderabad' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                        
                //         <img id='logo8' src='/images/AU.png' alt='Anna University' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                        
                //     </marquee>
                // )
                
                :
                (
                    <>
                        <img src='/images/IITMadras.png' alt='IIT Madras' style={{ padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                        <img src='/images/IITPatna.png' alt='IIT Patna' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                        <img src='/images/CDAC.png' alt='CDAC' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                        <img src='/images/IITBombay.png' alt='IIT Bombay' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                        <img src='/images/IIIT.png' alt='IIIT Hyderabad' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                        <img src='/images/ICFOSS.png' alt='ICFOSS' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                        <img src='/images/UH.png' alt='University of Hyderabad' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                        <img id='logo8' src='/images/AU.png' alt='Anna University' style={{padding: '0px 15px', height: '7vh', flex: 'none'}}/>
                    </>
                )
            }
                
                
            </Grid>
        </Grid>
    );
}

export default Credits;