import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link } from "react-router-dom";
import { getSessionParameter } from "../../actions/session";
import { dispatchLogout } from "../../actions/sign-out";
import { makeStyles } from "@material-ui/core/styles";
import { useWindowDimensions } from "./WindowDimensions";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  outlined: {
    height: "auto",
    color: "#005893",
    borderRadius: "12px",
    border: "1px solid #005893",
    backgroundColor: "#eaf2f9",
    "&:hover": {
      backgroundColor: "#eaf2f9",
    },
  },
}));

const drawerWidth = 200;

const TopBar1 = (props) => {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.only("sm"));
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));

  const classes = useStyles();

  const { width, height } = useWindowDimensions();
  const [mobileDisplay, setMobileDisplay] = useState(false);
  let paperWidth;
  let paperHeight;
  if (width < 960) {
    if (mobileDisplay == false) {
      setMobileDisplay(true);
    }
  } else {
    if (mobileDisplay == true) {
      setMobileDisplay(false);
    }
  }

  var isLoggedIn;
  const token = getSessionParameter("IS_LOGGEDIN");

  if (!token) {
    isLoggedIn = false;
  } else {
    isLoggedIn = true;
  }
  const code = new URL(window.location.href).searchParams.get("code");
  if (code) {
    isLoggedIn = true;
  }
  const [isClosed] = useState(false);

  const handleLogoutBtnClick = async (e) => {
    dispatchLogout();
  };

  const IIITLogo = (
    <Grid
      item
      container
      direction="column"
      alignItems={isXS ? "center" : "flex-start"}
      style={{
        paddingLeft: isXS ? 0 : "2vw",
        paddingTop: isXS ? 0 : "3vh",
      }}
    >
      <Link to={{ pathname: "/" }} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
        <img
          src={`images/IIITLogo.png`}
          alt="IIIT"
          style={{
            height:
              window.innerWidth < window.innerHeight && isXS ? "20vw" : "7vw",
          }}
        />
      </Link>
    </Grid>
  );

  return (
    <>
      <Grid
        item
        container
        style={{ paddingTop: "1vh", zIndex: "2", backgroundColor: "white" }}
      >
        <Grid id="topbar" item container xs={12} alignItems="center" style={{ paddingLeft: "0.5vw", paddingBottom: "0.5vw" }}>

          <Grid item xs={12} sm={8} md={8} lg={7} xl={7}>
            <img
              src={`images/IIITLogo.png`}
              alt="IIIT"
              style={{
                height:
                  window.innerWidth < window.innerHeight && isXS ? "25vw" : "3.4vw",
              }}
            />
          </Grid>
        </Grid>

        <Grid
          id="nav-menu"
          item
          container
          xs={12}
          alignContent="center"
          style={{
            height: "45px",
            backgroundColor: "#0F2649",
            borderColor: "#e7e7e7",
            fontSize: `calc(10px + 0.15vw + 0.2vh)`,
            paddingLeft: "18vw"
          }}
        >
          <Grid
            item
            container
            xs={11}
            sm={11}
            md={11}
            lg={11}
            xl={11}
            style={{ textAlign: "center", fontSize: 15, fontWeight: "bold" }}

          >
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <a href="https://himangy.iiithcanvas.com" style={{ color: "White" }}>
                Home
              </a>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <a href="https://himangy.iiithcanvas.com/objective" style={{ color: "White" }}>
                Objectives
              </a>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <a
                href="https://himangy.iiithcanvas.com/consortium"
                style={{ color: "White" }}
              >
                Consortium Members

              </a>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <a
                href="https://himangy.iiithcanvas.com/documents"
                style={{ color: "White" }}
              >
                Documents

              </a>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <span
                style={{ fontWeight: "700", borderBottom: "3px solid orange", color: "White" }}
              >
                Applications
              </span>
            </Grid>
            <Grid item xs={1} sm={2} md={2} lg={2} xl={2}>
              <a href="https://himangy.iiithcanvas.com/userleaderboard" style={{ color: "White" }}>
                Leader Board
              </a>
            </Grid>
            <Grid item xs={1} sm={2} md={2} lg={2} xl={2}>
              <a href="https://himangy.iiithcanvas.com/addtools" style={{ color: "White" }}>
                Himangy Tools
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TopBar1;
