import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { scrollTo } from "../utils";
import { getConfig, getTokenString } from "../config";
import store from 'store';
import {setUser, getSessionParameter, getAllSessionParameters, decodeIdToken, initAuthenticatedSession} from "../actions/session";


const CONFIG = getConfig();

const LoginPage = () => {
//   store.set('loggedIn', false);
  
  useEffect(() => {
    scrollTo("root");
  });



  // let CONFIG = getConfig();
  let tokenSring = "Basic " + getTokenString();

  const token = getSessionParameter("ACCESS_TOKEN");
  if (!token) {
    const code = new URL(window.location.href).searchParams.get("code");
    
    if (!code) {
      // store.set("loggedIn", false);
      const session = getAllSessionParameters();

      let authorizeRequest = `${CONFIG.AUTHORIZE_ENDPOINT}?response_type=${CONFIG.RESPONSE_TYPE}&scope=${CONFIG.SCOPE}&redirect_uri=${CONFIG.REDIRECT_URI}&client_id=${CONFIG.CLIENT_ID}`;
      window.location.href = authorizeRequest;
      // store.set("loggedIn", true);
    } else {
      if (code) {
        fetch("https://apicallhttps.iiithcanvas.com/token", {
          method: "POST",
          headers: {
            Authorization: tokenSring,
            "Content-Type": "application/x-www-form-urlencoded"
          },
          // body: 'grant_type=password&username=admin&password=admin&scope=openid'
          body: `grant_type=authorization_code&code=${code}&redirect_uri=${CONFIG.REDIRECT_URI}&scope=openid&client_id=${CONFIG.CLIENT_ID}`
        })
          .then((response) => {
            if (response.status !== 200) {
              return Promise.reject(
                new Error(
                  "Invalid status code received in the token response: " +
                    response.status
                )
              );
            }
            return response.json();
          })
          .then((responseData) => {
            initAuthenticatedSession(responseData);
            const token = getSessionParameter("ACCESS_TOKEN");
            return [responseData, decodeIdToken(responseData.id_token)];
          })
          .then((responseData) => {
            var session_user = responseData[1].sub;
            var projectName = responseData[1].country;
            var roles = responseData[1].groups;
            var isAdmin = roles.includes("taskmanager_project_admin")
              ? true
              : false;
            var isUser = roles.includes("taskmanager_project_user")
              ? true
              : false;
            if (responseData[1].country != "") {
              // submitUser(
              //   responseData[1].sub,
              //   responseData[1].email,
              //   responseData[1].country,
              //   responseData[1].groups,
              //   responseData[1].groups.includes("taskmanager_project_admin")
              //     ? true
              //     : false
              // );
            }
            setUser(session_user, isAdmin, isUser, projectName);
            // Refresh for fetch purposes (temporary fix)
            window.location.href = CONFIG.CLIENT_URL;
            window.location.reload();
            // alert("Welcome, " + responseData[1].sub);
            return responseData;
          })
          .catch((error) => {
            console.log("error is", error);
            return Promise.reject(error);
          });
      }
    }
  }



  // const session = getAllSessionParameters();
  // let authorizeRequest = `${ CONFIG.AUTHORIZE_ENDPOINT }?response_type=${ CONFIG.RESPONSE_TYPE }&scope=${ CONFIG.SCOPE }&redirect_uri=${ CONFIG.REDIRECT_URI }&client_id=${ CONFIG.CLIENT_ID }`;
  // window.location.href = authorizeRequest;


  //   store.set('loggedIn', true);

  return (
    // <div
    //   className="landing"
    //   style={{ width: "100%", height: "flex", backgroundColor: "#f9f9f9" }}
    // >
    // </div>
    <Redirect to="/" />
  );
};

export default LoginPage;
