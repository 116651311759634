import React, { useEffect, useRef, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import NativeSelect from "@material-ui/core/NativeSelect";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useLazyQuery, useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GetAppIcon from "@material-ui/icons/GetApp";
import MicIcon from "@material-ui/icons/Mic";
import StopIcon from "@material-ui/icons/Stop";
import PauseIcon from "@material-ui/icons/Pause";
import { UPLOAD_FILE } from "../../../queries/TaskQueries";
import "../../../styles/loader.css";
import { Tooltip, Button, CircularProgress } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { useMemo } from "react";
import {
  NEW_TEMP_TASK,
  GET_TEMP_TASK_BY_ID,
} from "../../../queries/TempTaskQueires";
import {
  GET_PIPELINES,
  GET_PIPELINE_BY_ID,
} from "../../../queries/PipelineQueries";
import { VTT_TO_TEXT } from "../../../queries/UtilQueries";
import { useWindowDimensions } from "../WindowDimensions";
import { Skeleton } from "@material-ui/lab";

const MicRecorder = require("mic-recorder-to-mp3");

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      fontSize: "clamp(8px, 1.5vh, 1.5vh)", //"calc(12px + 1.5vmin)",
      height: "7vh",
      maxHeight: "7vh",
    },
  },
  outlined: {
    paddingRight: 0,
  },
  noBorder: {
    border: "none",
  },
});

function PipelineWidget(props) {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only("xs"));
  const isSM = useMediaQuery(theme.breakpoints.only("sm"));

  const setTTSValue = {};

  const classes = useStyles();

  const { width, height } = useWindowDimensions();

  const [TTSInputText, setTTSInputText] = useState("");
  setTTSValue["inputText"] = setTTSInputText;
  const [inputChanged, setInputChanged] = useState(true);

  const [showLoader, setShowLoader] = useState(false);
  const [errorStatement, setErrorStatement] = useState(" ");

  const [helperText, setHelperText] = useState("Tap on mic to speak");

  const [isDocumentMode, setIsDocumentMode] = useState(false);
  const [documentModeState, setDocumentModeState] = useState("selectFile");
  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState(null);

  const [tempTaskCompleted, toggleTempTaskCompleted] = useState(false);
  const [tempTaskID, setTempTaskID] = useState();
  const [prevTempTaskID, setPrevTempTaskID] = useState();
  const [outputFileURL, setOutputFileURL] = useState();
  const [pollID, setPollID] = useState();

  const [micLoader, toggleMicLoader] = useState(false);
  const [RecordingStatus, setRecordingStatus] = useState(MicIcon);
  const [pipeline, setPipeline] = useState(null);
  const [pipelineID, setPipelineID] = useState(null);
  const [taskTemplate, setTaskTemplate] = useState(null);
  const [currentTaskTemplateIndex, setCurrentTaskTemplateIndex] =
    useState(null);

  const [jobInProgress, toggleJobInProgress] = useState(false);
  const [asrText, setASRText] = useState(" ");
  const [mtText, setMTText] = useState(" ");
  const [loaderText, setLoaderText] = useState(" ");

  const [currentAudio, setCurrentAudio] = useState(null);
  const [audioPlaying, toggleAudioPlaying] = useState(false);

  // const [temp, toggleTemp] = useState(false);
  // useEffect(() => {
  //   if (!temp) {
  //     let audio = new Audio(
  //       "https://bahubhashak-iiit.s3.us-east-2.amazonaws.com/translation/b95b9cab-5d82-4611-ac60-408dcbe918f0-name-pipeline-widget-scripted.wav"
  //     );
  //     audio.autoplay = true;
  //     audio.load();
  //     toggleAudioPlaying(true);
  //     setCurrentAudio(audio);
  //     toggleTemp(true);
  //   }
  // });

  let errorSection = (
    <Grid
      item
      container
      justify="center"
      alignItems="center"
      xs={12}
      style={{ height: "1.5vh" }}
    >
      {errorStatement != " " ? (
        <>
          <Grid item style={{ marginRight: 10 }}>
            <img src="images/Error.png" alt="err" />
          </Grid>
          <span
            style={{
              color: "#d01010",
              fontSize: "clamp(12px, 1.43vh, 1.43vh)",
            }}
          >
            {errorStatement}
          </span>
        </>
      ) : null}
    </Grid>
  );

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ noClick: true, noKeyboard: true });

  if (acceptedFiles.length && documentModeState == "selectFile") {
    setFile(acceptedFiles[0]);
    setDocumentModeState("fileSelected");
  }

  const [getPipeline, { loading: loadingPipeline }] = useLazyQuery(
    GET_PIPELINE_BY_ID,
    {
      onCompleted: (data) => {
        let pipeline = data.getPipeline;
        setPipeline(pipeline);
      },
      onError: (err) => {
        errorOccurred(err);
      },
    }
  );

  const { loading: loadingPipelines, data: pipelines } = useQuery(
    GET_PIPELINES,
    {
      onCompleted: (data) => {
        let id = data.getPipelines[0]._id;
        setPipelineID(id);
      },
      onError: (err) => {
        errorOccurred(err);
      },
    }
  );

  const [
    getTempTask,
    { loading: fetchingTempTask, error: errorInTempTask, data: tempTask },
  ] = useLazyQuery(GET_TEMP_TASK_BY_ID, {
    fetchPolicy: "network-only",
    skip: !tempTaskID || (tempTaskID && tempTaskCompleted),
    variables: { tempTaskID: tempTaskID },
  });

  const startPollingTempTask = () => {
    console.log("Started polling!");
    const pollTempTask = setInterval(() => {
      getTempTask();
    }, 5000);
    setPollID(pollTempTask);
  };

  const stopPollingTempTask = () => {
    clearInterval(pollID);
    console.log("Stopped polling!");
  };

  const [newTempTask, { loading: creatingNewTempTask }] = useMutation(
    NEW_TEMP_TASK,
    {
      onCompleted: (data) => {
        console.log("Created new temp task - ", data.newTempTask);
        let id = data.newTempTask._id;
        let prevID = tempTaskID;
        setPrevTempTaskID(prevID);
        setTempTaskID(id);
        startPollingTempTask();
        setOutputFileURL(null);
      },
      onError: (err) => {
        errorOccurred(err);
      },
    }
  );

  const [uploadFile, { loading: uploading }] = useMutation(UPLOAD_FILE, {
    onCompleted: (data) => {
      setFileURL(data.uploadFile.uri);
      setCurrentTaskTemplateIndex(0);
      console.log("Upload complete!");
    },
    onError: (err) => {
      errorOccurred(err);
      if (acceptedFiles.length) acceptedFiles.pop();
      setDocumentModeState("selectFile");
    },
  });

  const fireTempTask = (template) => {
    let jsonBody = {
      Type: template.Type.Type,
      APIName: template.API.APIName,
      InputFileURL: fileURL,
      SourceLanguage: template.SourceLanguage,
      TargetLanguage: template.TargetLanguage,
      OutputType: template.OutputFileType,
    };
    newTempTask({ variables: jsonBody });
    if (template.Type.Type === "MT") setLoaderText("Translating");
    else if (template.Type.Type === "TTS") setLoaderText("Synthesizing");
    toggleTempTaskCompleted(false);
    setFileURL(null);
  };

  const [vttToText, { loading: loading_txt }] = useLazyQuery(VTT_TO_TEXT, {
    onCompleted: (data) => {
      let data1 = JSON.parse(data.getJSONScript);

      const tempText = data1.cues.reduce(function (result, cue) {
        return result.concat(cue.text, " ");
      }, "");

      if (taskTemplate.Type.Type === "ASR") setASRText(tempText);
      else setMTText(tempText);
      setCurrentTaskTemplateIndex(currentTaskTemplateIndex + 1);
    },
    onError: (err) => {
      errorOccurred(err);
    },
  });

  useEffect(() => {
    if (pipeline) {
      if (currentTaskTemplateIndex >= pipeline.NumberOfTaskTemplates) {
        // Stop condition
        setFileURL(null);
        setShowLoader(false);
        toggleJobInProgress(false);
        setLoaderText(" ");
      } else {
        console.log("Updated index - ", currentTaskTemplateIndex);
        let temp = pipeline.TaskTemplates[currentTaskTemplateIndex];
        if (!taskTemplate || taskTemplate._id !== temp._id) {
          setTaskTemplate(temp);
          fireTempTask(temp);
        }
      }
    }
  }, [currentTaskTemplateIndex]);

  useEffect(() => {
    if (pipelineID) {
      setErrorStatement(" ");
      setASRText(" ");
      setMTText(" ");
      toggleJobInProgress(false);
      getPipeline({ variables: { pipelineID: pipelineID } });
    }
  }, [pipelineID]);

  useEffect(() => {
    if (tempTask && tempTask.getTempTaskByID) {
      let status = tempTask.getTempTaskByID.Status;
      if (tempTask.getTempTaskByID._id == tempTaskID) {
        if (
          status === "Completed" &&
          tempTask.getTempTaskByID._id !== prevTempTaskID
        ) {
          toggleTempTaskCompleted(true);
          stopPollingTempTask();

          console.log(
            taskTemplate.Type.Type,
            " output recieved! - ",
            tempTask.getTempTaskByID.OutputFileURL
          );
          if (
            taskTemplate.Type.Type === "ASR" ||
            taskTemplate.Type.Type === "MT"
          ) {
            vttToText({
              variables: {
                scriptURL: tempTask.getTempTaskByID.OutputFileURL,
              },
            });
            let scriptURL = tempTask.getTempTaskByID.OutputFileURL;
            setFileURL(scriptURL);
          } else if (taskTemplate.Type.Type === "TTS") {
            let audioURL = tempTask.getTempTaskByID.OutputFileURL;
            setOutputFileURL(audioURL);
            const audio = new Audio(audioURL);
            audio.autoplay = true;
            audio.load();
            setCurrentAudio(audio);
            toggleAudioPlaying(true);
            setCurrentTaskTemplateIndex(currentTaskTemplateIndex + 1);
          }
        } else if (status === "Error") {
          console.log(taskTemplate.Type.Type, " Failed!");
          stopPollingTempTask();
          setShowLoader(false);
          errorOccurred("Error in job!");
          if (acceptedFiles.length) acceptedFiles.pop();
          setDocumentModeState("selectFile");
        }
      }
    }
  }, [tempTask]);

  useEffect(() => {
    if (errorInTempTask) {
      let status = "Error in synthesis!";
      stopPollingTempTask();
      setShowLoader(false);
      errorOccurred(errorInTempTask);
      if (acceptedFiles.length) acceptedFiles.pop();
      setDocumentModeState("selectFile");
    }
  }, [errorInTempTask]);

  useEffect(() => {
    if (currentAudio) {
      currentAudio.addEventListener("ended", () => toggleAudioPlaying(false));
      return () => {
        currentAudio.removeEventListener("ended", () =>
          toggleAudioPlaying(false)
        );
      };
    }
  });

  function playAudio() {
    if (currentAudio && currentAudio.paused) {
      toggleAudioPlaying(true);
      currentAudio.play();
    }
  }

  // TTS player
  const handleTTSPlay = async () => {
    if (audioPlaying) {
      currentAudio.pause();
      toggleAudioPlaying(false);
    } else {
      playAudio();
    }
  };

  const recorder = useMemo(
    () =>
      new MicRecorder({
        bitRate: 128,
      }),
    []
  );

  let loader = (
    <Grid
      container
      justifyContent="center"
      className="loader"
      style={{ position: "relative", textAlign: "center" }}
    >
      <span className="stroke"></span>
      <span className="stroke"></span>
      <span className="stroke"></span>
      <span className="stroke"></span>
      <span className="stroke"></span>
      <span className="stroke"></span>
      <span className="stroke"></span>
    </Grid>
  );

  function errorOccurred(err) {
    console.log("Error in task is", err);
    if (err) {
      setHelperText(" ");
      toggleJobInProgress(false);
      setErrorStatement("Technical problem. Please retry...");
      if (showLoader == true) setShowLoader(false);
    }
  }

  function getFileSize(fileSize) {
    return fileSize < 1024
      ? fileSize + " bytes"
      : fileSize < 1024 * 1024
      ? (fileSize / 1024).toFixed(2) + "kb"
      : fileSize < 1024 * 1024 * 1024
      ? (fileSize / (1024 * 1024)).toFixed(2) + "mb"
      : (fileSize / (1024 * 1024 * 1024)).toFixed(2) + "gb";
  }

  let fileSize;
  if (acceptedFiles.length) {
    fileSize = getFileSize(acceptedFiles[0].size);
  }

  const handleMicClick = () => {
    if (RecordingStatus === MicIcon) {
      setASRText(" ");
      setMTText(" ");
      setErrorStatement(" ");
      setOutputFileURL(null);
      setCurrentAudio(null);
      toggleAudioPlaying(false);
      recorder
        .start()
        .then(() => {
          setErrorStatement(" ");
          setRecordingStatus(StopIcon);
          setHelperText("Click on the icon to stop recording");
        })
        .catch((err) => {
          console.error("Error in recording is...", err);
          setHelperText("Click on the mic to start recording");
        });
    } else {
      recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          setLoaderText("Transcribing");
          setShowLoader(true);
          toggleJobInProgress(true);
          setHelperText("Your audio is being scripted");
          // do what ever you want with buffer and blob
          // Example: Create a mp3 file and play
          const file = new File(buffer, "pipeline-widget.mp3", {
            type: blob.type,
            lastModified: Date.now(),
          });

          console.log("Triggered upload file!");

          uploadFile({
            variables: {
              file: file,
            },
          });
        })
        .catch((e) => {
          setHelperText("Click on the mic to start recording");
          alert("We could not retrieve your message");
          console.log(e);
        });
      setRecordingStatus(MicIcon);
      setHelperText("Click on the mic to start recording");
    }
  };

  const isMobileDisplay = () => width < 960;

  let speakerButton = (
    <Grid container justifyContent="flex-end">
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <span
          style={{
            color:
              jobInProgress || !outputFileURL ? "gray" : "rgba(11, 94, 151, 1)",
            fontSize: "12px",
          }}
        >
          {pipeline ? pipeline.TargetLanguage : null}
        </span>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          width: "1vw",
          marginRight: isMobileDisplay() ? "1vmax" : "1vmax",
        }}
      >
        <IconButton
          onClick={handleTTSPlay}
          disabled={jobInProgress || !outputFileURL}
          style={{
            backgroundColor:
              jobInProgress || !outputFileURL
                ? "lightgray"
                : "rgba(11, 94, 151, 1)",
          }}
        >
          {audioPlaying ? (
            <PauseIcon
              style={{
                color: "white",
                height: isMobileDisplay() ? "2.5vmax" : "1.5vmax",
                width: isMobileDisplay() ? "2.5vmax" : "1.5vmax",
              }}
            />
          ) : (
            <VolumeUpIcon
              style={{
                height: isMobileDisplay() ? "2.5vmax" : "1.5vmax",
                width: isMobileDisplay() ? "2.5vmax" : "1.5vmax",
                color: jobInProgress || !outputFileURL ? "white" : "white",
              }}
            />
          )}
        </IconButton>
      </Grid>
    </Grid>
  );

  let micButton = (
    <Grid container justifyContent="flex-start">
      <Grid
        item
        xs={12}
        style={{
          maxWidth: "1vw",
          marginLeft: isMobileDisplay() ? "1.4vmax" : "1.25vmax",
        }}
      >
        <span
          style={{
            color:
              jobInProgress || audioPlaying ? "gray" : "rgba(11, 94, 151, 1)",
            fontSize: "12px",
          }}
        >
          {pipeline ? pipeline.SourceLanguage : null}
        </span>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          width: "1vw",
          marginLeft: isMobileDisplay() ? "1vmax" : "1vmax",
        }}
      >
        <IconButton
          onClick={handleMicClick}
          disabled={jobInProgress || audioPlaying}
          style={{
            background:
              jobInProgress || audioPlaying
                ? "lightgray"
                : "rgba(11, 94, 151, 1)",
          }}
        >
          <RecordingStatus
            style={{
              height: isMobileDisplay() ? "2.5vmax" : "1.5vmax",
              width: isMobileDisplay() ? "2.5vmax" : "1.5vmax",
              color: "white",
            }}
          />
        </IconButton>
      </Grid>
    </Grid>
  );

  return (
    <Grid item container>
      <Grid
        id="1"
        item
        container
        style={{
          minWidth: "267px",
          minHeight: "270px",
          width: isXS ? "80vw" : isSM ? "50vw" : "28vw",
          height: "38.33vh",
          animation: "compress-card .6s ease-out forwards",
        }}
        onClick={(e) => {
          props.gridClick(e);
        }}
      >
        <Grid
          item
          container
          style={{ width: "100%", height: "10.3%" }}
          direction="row"
          xs={12}
        >
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Tooltip
              title="Get your speech translated to a desired language"
              placement="top-start"
            >
              <Button>Speech To Speech</Button>
            </Tooltip>
          </Grid>
          <Grid
            item
            container
            xs={6}
            justify="flex-end"
            style={{ height: "100%" }}
          >
            <Grid
              item
              container
              justify="flex-end"
              style={{ height: "inherit", position: "relative" }}
            ></Grid>
          </Grid>
        </Grid>
        <Paper
          id="TTSPaper"
          elevation={0}
          style={{
            width: "100%",
            height: "89.7%",
            borderRadius: "0px",
            border: "2px solid #0B5E97",
            marginBottom: "5%",
          }}
        >
          <Grid
            container
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{ paddingBottom: "0px", height: "100%" }}
          >
            <Grid item container>
              <Grid
                item
                container
                xs={12}
                md={12}
                lg={12}
                xl={12}
                justify="flex-end"
              >
                <NativeSelect
                  className={`${classes.nativeInput} ${classes.outlined}`}
                  value={pipelineID}
                  size="small"
                  onChange={(e) => setPipelineID(e.target.value)}
                  disableUnderline={true}
                  style={{ color: "rgb(11, 94, 151)", maxWidth: 200 }}
                >
                  {pipelines && pipelines.getPipelines
                    ? pipelines.getPipelines.map((pipeline) => (
                        <option value={pipeline._id}>{pipeline.Name}</option>
                      ))
                    : null}
                </NativeSelect>
              </Grid>
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              style={{ width: "100%", height: "80%" }}
            >
              <>
                {/* <Grid
                  item
                  container
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    marginLeft: "0.75vmax",
                    height: "2vh",
                    maxHeight: "2vh",
                  }}
                >
                  <span
                    style={{
                      color: asrText != " " ? "rgb(11, 94, 151)" : "gray",
                    }}
                  >
                    {jobInProgress && asrText === " " ? "Transcribing" : " "}
                    {asrText != " " && pipeline ? pipeline.SourceLanguage : " "}
                  </span>
                </Grid> */}
                {jobInProgress && asrText === " " ? (
                  <Grid
                    item
                    container
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    justifyContent="center"
                    style={{ height: "7vh" }}
                  >
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      borderBottom:
                        asrText !== " " ? "1px solid #e3e3e3" : null,
                    }}
                  >
                    <TextField
                      fullWidth
                      multiline
                      value={asrText}
                      variant="outlined"
                      rows={2}
                      InputProps={{
                        readOnly: true,
                        classes: {
                          notchedOutline: classes.noBorder,
                        },
                      }}
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        borderRadius: "0px",
                      }}
                      className={classes.root}
                    />
                  </Grid>
                )}

                <Grid
                  item
                  container
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  alignItems="center"
                  style={{
                    marginLeft: "0.75vmax",
                    height: "2vh",
                    maxHeight: "2vh",
                    textAlign: "center",
                    color: "#909090",
                  }}
                >
                  <span style={{ width: "100%" }}>
                    {jobInProgress || outputFileURL
                      ? " "
                      : "Tap on the mic to speak"}
                  </span>
                </Grid>

                {jobInProgress && asrText !== " " && mtText === " " ? (
                  <Grid
                    item
                    container
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    justifyContent="center"
                    style={{ height: "7vh" }}
                  >
                    <Skeleton width="80%" />
                    <Skeleton width="80%" />
                  </Grid>
                ) : (
                  <Grid item container xs={12} md={12} lg={12} xl={12}>
                    <TextField
                      fullWidth
                      multiline
                      value={mtText}
                      variant="outlined"
                      rows={2}
                      style={{
                        width: "100%",
                        backgroundColor: "white",
                        border: "0px",
                      }}
                      className={classes.root}
                      InputProps={{
                        readOnly: true,
                        classes: { notchedOutline: classes.noBorder },
                      }}
                      lightgray
                    />
                  </Grid>
                )}
                <Grid item xs={2} style={{ textAlign: "left" }}>
                  {micButton}
                </Grid>
                <Grid item xs={8} style={{ width: "5vw", textAlign: "center" }}>
                  {errorStatement !== " " ? (
                    errorSection
                  ) : jobInProgress ? (
                    <span style={{ color: "rgba(11, 94, 151, 1)" }}>
                      {loaderText}
                    </span>
                  ) : null}
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  {speakerButton}
                </Grid>
              </>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default PipelineWidget;
