import React, { useState, useEffect, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ASRCard from "./BannerComponents/ASRCard";
import MTCard from "./BannerComponents/MTCard";
import TTSCard from "./BannerComponents/TTSCard";
import { useWindowDimensions } from "./WindowDimensions";
import { Button, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PipelineWidget from "./BannerComponents/PipelineWidget";

import "../../styles/Card.css";

const Banner = (props) => {
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.only("sm"));
  const isXS = useMediaQuery(theme.breakpoints.only("xs"));

  const { width, height } = useWindowDimensions();
  const [mobileDisplay, setMobileDisplay] = useState(false);
  const [gridWidth, setGridWidth] = useState(true);
  const [diff, setDiff] = useState(false);

  const [selectedCard, setSelectedCard] = useState(1);

  useEffect(() => {
    if (gridWidth !== true && gridWidth !== false && diff === false && isXS)
      setDiff(-gridWidth);
    else if (
      gridWidth !== true &&
      gridWidth !== false &&
      diff === false &&
      isSM
    )
      setDiff(-gridWidth / 2);

    if (diff !== false) {
      if (selectedCard == 2) {
        if (isSM) setDiff((3 * -gridWidth) / 2);
        else if (isXS) {
          setDiff(-2 * gridWidth);
        } else setDiff(-gridWidth);
      } else if (selectedCard == 1) {
        if (isSM) setDiff(-gridWidth / 2);
        else if (!isXS) setDiff(0);
        else setDiff(-gridWidth);
      } else if (selectedCard == 0) {
        if (isSM) setDiff(gridWidth / 2);
        else if (isXS) setDiff(0);
        else setDiff(gridWidth);
      }
    }
  }, [gridWidth]);

  //Carousel start
  let gridRef = [];

  gridRef[0] = useRef(null);
  gridRef[1] = useRef(null);
  gridRef[2] = useRef(null);
  gridRef[3] = useRef(null);

  if (gridRef[0].current) {
    //.children[0].children[0]
    if (gridWidth === true || gridWidth === false)
      setGridWidth(gridRef[1].current.children[0].children[0].offsetWidth);
  } else {
    setTimeout(() => {
      if (gridWidth === true || gridWidth === false) setGridWidth(!gridWidth);
    });
  }

  if (
    gridWidth !== true &&
    gridWidth !== false &&
    gridRef[1].current.children[0].children[0].offsetWidth != gridWidth
  ) {
    setGridWidth(gridRef[1].current.children[0].children[0].offsetWidth);
  }

  useEffect(() => {
    if (gridRef[1].current)
      gridRef[1].current.children[0].children[0].style.animation =
        "scale-card .6s ease-out forwards";
  }, []);

  useEffect(() => {
    for (let i = 0; i < cards.length; i++) {
      if (diff <= 700 && (diff >= -gridWidth * 2 || isSM || isXS)) {
        if (gridRef[i].current) {
          gridRef[i].current.style.transform = "translate(" + diff + "px, 0px)";
        }
      } else {
        if (diff > 700) {
          setDiff(diff - gridWidth);
          setSelectedCard(selectedCard + 1);
        } else {
          setDiff(diff + gridWidth);
          if (selectedCard > 2) setSelectedCard(selectedCard - 1);
        }
      }
    }
  }, [diff]);

  function leftArrowAnimation() {
    for (let i = 0; i < cards.length; i++) {
      let cardNumber = (diff - 0) / -gridWidth;
      if (isSM) cardNumber -= 0.5;
      else if (isXS) cardNumber -= 1;

      if (cardNumber == Number(gridRef[i].current.id)) {
        gridRef[i].current.children[0].children[0].style.animation =
          "scale-card .6s ease-out forwards";
        gridRef[
          i
        ].current.children[0].children[0].children[0].style.pointerEvents =
          "auto";
        gridRef[
          i
        ].current.children[0].children[0].children[1].style.pointerEvents =
          "auto";
        if (i < cards.length - 1) {
          gridRef[
            i + 1
          ].current.children[0].children[0].children[0].style.pointerEvents =
            "none";
          gridRef[
            i + 1
          ].current.children[0].children[0].children[1].style.pointerEvents =
            "none";
          gridRef[i + 1].current.children[0].children[0].style.animation =
            "compress-card .6s ease-out forwards";
        }
      }
    }
    setDiff(diff + gridWidth);
    setSelectedCard(selectedCard - 1);
  }

  function rightArrowAnimation() {
    for (let i = 0; i < cards.length; i++) {
      let cardNumber = (diff - gridWidth - 0) / -gridWidth + 1;
      if (isSM) cardNumber -= 0.5;
      else if (isXS) cardNumber -= 1;

      if (cardNumber == Number(gridRef[i].current.id)) {
        gridRef[i].current.children[0].children[0].style.animation =
          "scale-card .6s ease-out forwards";
        gridRef[
          i
        ].current.children[0].children[0].children[0].style.pointerEvents =
          "auto";
        gridRef[
          i
        ].current.children[0].children[0].children[1].style.pointerEvents =
          "auto";
        if (i > 0) {
          gridRef[
            i - 1
          ].current.children[0].children[0].children[0].style.pointerEvents =
            "none";
          gridRef[
            i - 1
          ].current.children[0].children[0].children[1].style.pointerEvents =
            "none";
          gridRef[i - 1].current.children[0].children[0].style.animation =
            "compress-card .6s ease-out forwards";
        }
      }
    }
    setDiff(diff - gridWidth);
    setSelectedCard(selectedCard + 1);
  }

  function gridClick(e) {
    //   if (e.clientX <= 520)
    //     leftArrowAnimation();
    //   else if (e.clientX >= 950)
    //     rightArrowAnimation();
    if (selectedCard + 1 == e.currentTarget.id) rightArrowAnimation();
    else if (selectedCard - 1 == e.currentTarget.id) leftArrowAnimation();
  }

  const cards = [];

  //onClick={e => gridClick(e)}
  cards[0] = (
    <Grid
      id="0"
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      ref={gridRef[0]}
      key={0}
      item
      container
      className="carouselItem"
      style={{ width: "100%" }}
    >
      <ASRCard gridClick={gridClick} />
    </Grid>
  );
  cards[1] = (
    <Grid
      id="1"
      xs={12}
      md={12}
      ref={gridRef[1]}
      key={1}
      item
      container
      className="carouselItem"
      style={{ width: "100%" }}
    >
      <PipelineWidget gridClick={gridClick} />
    </Grid>
  );
  cards[2] = (
    <Grid
      id="2"
      xs={12}
      md={12}
      ref={gridRef[2]}
      key={2}
      item
      container
      className="carouselItem"
      style={{ width: "100%" }}
    >
      <MTCard gridClick={gridClick} />
    </Grid>
  );
  cards[3] = (
    <Grid
      id="3"
      xs={12}
      md={12}
      ref={gridRef[3]}
      key={3}
      item
      container
      className="carouselItem"
      style={{ width: "100%" }}
    >
      <TTSCard gridClick={gridClick} />
    </Grid>
  );

  // for (let i = 3; i < 8; i++) {
  //     cards[i] = <Grid id={i} ref={gridRef[i]} key={i} item className='carouselItem' onClick={e => gridClick(e)}>
  //         <MyCard />
  //     </Grid>;
  // }
  //Carousel end

  // viewport.onWindowResize()
  var items = [];
  let renderContent;
  if (width >= 960) {
    if (mobileDisplay == true) setMobileDisplay(false);
    items.push({
      name: "translationCards",
      description: "ASR, MT and TTS card",
      component: TTSCard,
    });

    renderContent = (
      <>
        <ASRCard />

        <MTCard />

        <TTSCard />
      </>
    );
  } else {
    if (mobileDisplay == false) setMobileDisplay(true);
    items.push({
      name: "ASRCard",
      description: "This is ASR Card",
      component: ASRCard,
    });

    items.push({
      name: "MTCard",
      description: "This is MT Card",
      component: MTCard,
    });

    items.push({
      name: "TTSCard",
      description: "This is TTS Card",
      component: TTSCard,
    });

    renderContent = <></>;
  }

  const history = (
    <Grid
      item
      container
      xs={4}
      direction="column"
      alignItems="center"
      style={{ display: "none" }}
    >
      <Grid item>
        <img
          src="images/History.png"
          alt="History"
          height="60px"
          width="60px"
        />
      </Grid>
      <Grid item style={{ color: "#055a95" }}>
        History
      </Grid>
    </Grid>
  );

  const documentSwitch = (
    <Grid
      item
      container
      xs={6}
      md={3}
      justify="center"
      alignItems="center"
      style={{ display: "none" }}
    >
      <InputLabel htmlFor="documentMode" title="Coming soon">
        <Grid
          item
          container
          style={{ height: "100%", color: "#005893" }}
          justify="center"
          alignItems="center"
        >
          Document
        </Grid>
      </InputLabel>
      <input type="checkbox" id="documentMode" style={{ display: "none" }} />
    </Grid>
  );

  const leftArrow = (
    <Grid
      item
      container
      xs={1}
      justify="center"
      style={{ width: "100%", height: "100%" }}
    >
      <Button disableElevation={true} fullWidth onClick={leftArrowAnimation}>
        <ArrowBackIosIcon fontSize="large" />
      </Button>
    </Grid>
  );

  const rightArrow = (
    <Grid
      item
      container
      xs={1}
      justify="center"
      style={{ width: "100%", height: "100%" }}
    >
      <Button disableElevation={true} fullWidth onClick={rightArrowAnimation}>
        <ArrowForwardIosIcon fontSize="large" />
      </Button>
    </Grid>
  );

  return (
    <Grid item cotnainer style={{ width: "100vw" }}>
      <Paper style={{ width: "100vw" }} elevation={0}>
        {/* , marginTop: "30px" */}
        {/* <Grid item container xs={12}>
          {isXS || isSM ? history : documentSwitch}
          <Grid xs={0} md={6} />
          <Grid item container xs={8} md={3} justify="center">
            {isXS || isSM ? documentSwitch : history}
            <Grid
              item
              container
              xs={6}
              md={4}
              direction="column"
              alignItems="center"
              style={{ display: "none" }}
            >
              <Grid item>
                <img
                  src="images/Saved.png"
                  alt="Saved"
                  height="60px"
                  width="60px"
                />
              </Grid>
              <Grid item style={{ color: "#055a95" }}>
                Saved
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid
          container
          xs={12}
          justify="space-between"
          style={{
            width: "100%",
            height: "39.16vh",
            minHeight: "280px",
            marginBottom: "15px",
          }}
          // minHeight: "320px"
        >
          {!isXS && !isSM ? leftArrow : null}

          <Grid
            item
            container
            justify="flex-start"
            xs={12}
            md={10}
            style={{ minHeight: "280px", height: "39.16vh" }}
          >
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="carouselContainer"
              wrap="nowrap"
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              style={{
                overflow: "hidden",
                minHeight: "280px",
                height: "39.16vh",
                zIndex: 2000,
              }}
            >
              {/* position: 'relative', */}

              {cards}

              {/* <Grid item container xs={12}>
                                <Grid item style={{width: '100%'}}>
                                    qwertypouqweropiqwerpoiuqwrepoiuqwer
                                </Grid>
                            </Grid> */}
            </Grid>
          </Grid>

          {!isXS && !isSM ? rightArrow : null}

          <Grid>{/* <img src='BannerImage.jpeg'></img> */}</Grid>
        </Grid>

        <Grid container xs={12}>
          <Grid item container xs={1} sm={2} md={3} />
          <Grid
            item
            container
            xs={10}
            sm={8}
            md={6}
            justify="center"
            alignItems="flex-end"
          >
            <Typography
              variant={isXS ? "h6" : isSM ? "h6" : "h5"}
              gutterBottom
              style={{
                color: "#f99746",
                fontWeight: "bold",
                textAlignLast: "center",
              }}
            >
              Understanding each other is{isXS ? <br /> : null} easy now
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Banner;
